import { Link } from "react-router-dom";
import LogoComponent from "../LogoComponent";


let TopComponentGeneral = () => {  
    return <>
        <div className="w-full mulish-font bg-gray-200 fixed z-20 p-[15px] py-[5px]">
            <div className="flex justify-around gap-5 item-center">
                <LogoComponent></LogoComponent>
                <div className="hidden md:flex flex-grow justify-end items-center">
                    <div className="flex items-center text-base font-bold gap-4">
                       
                    </div>      
                    <div className="flex">

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default TopComponentGeneral;