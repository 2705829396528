

let InputComponent2 = ({type, placeholder, onChange}) => {
    return <>
        <div className="mulish-font ">
            <input onChange={onChange} className="px-[32px] w-full pt-[15px] pb-[12px] font-regular text-base bg-rouzo-light-blue-2 rounded-[7px]" placeholder={placeholder} type={type}></input>
        </div>
    </>
}

export default InputComponent2