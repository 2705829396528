
let Impact = () => {
    return <>
        <div className="bg-rouzo-blue-gray flex flex-col  py-20 px-10 md:px-[100px]">

            <div className="text-right font-light flex flex-col gap-2">
                <p>____________IMPACT</p>
                <p className="font-bold text-right">SMALL STEPS, BIG CHANGE</p>
            </div>
            <div className="flex lota-font font-lota gap-3 flex-wrap font-thin">

                <p>
                    Across Africa, millions of small businesses, the lifeblood of the continent, struggle. They lack the digital tools and access to funding needed to grow and compete. At the same time, environmentally conscious people yearn for ways to grow their money in a way that benefits both their wallets and the planet.
                </p>
                <p>
                    Our community isn't made up of just faceless People. They're everyday people – millennials and Gen Z's who understand the importance of sustainability and want their money to make a real difference.
                </p>
                <p>
                    Our impact is undeniable. Rouzo isn't just about financing; it's about fostering a community with a shared vision for a sustainable future.
                </p>
                <p>By supporting clean energy projects, green jobs, and innovative technologies, Rouzo directly contributes to several Sustainable Development Goals (SDGs) set by the United Nations; which includes:
                </p>
                <div>
                    <p>
                        <span className="font-bold">Goal 1:</span>
                        By providing necessary tools to build wealth and promote sustainable economic practices, we are reducing poverty.
                    </p>
                    <p>
                        <span className="font-bold">Goal 2:</span>
                        We are doubling the agricultural productivity and incomes of small-scale food producers, in particular women, indigenous peoples, through secure and equal access to productive resources and financial services.

                    </p>
                    <p>
                        <span className="font-bold">Goal 7:</span>
                        By facilitating access to financing for renewable energy projects, Rouzo directly contributes to increased adoption of clean energy sources.
                    </p>
                    <p>
                        <span className="font-bold">Goal 8:</span>
                        Empowering green businesses with financial tools and streamlined operations fosters job creation and economic growth in the sustainability sector.
                    </p>
                    <p>
                        <span className="font-bold">Goal 9:</span>
                        Rouzo promotes innovation in the green economy by connecting businesses with the capital they need to develop and implement new sustainable technologies.

                    </p>
                    <p>
                        <span className="font-bold">Goal 13:</span>
                        By supporting the growth of green businesses, Rouzo fosters a transition towards a more sustainable future, mitigating climate change impacts.
                    </p>
                </div>
                <p>
                    Our journey hasn't been easy, but the results speak for themselves. Rouzo has already facilitated nearly N2 Billion in transactions, impacting the lives of 5,000 individuals of which 65% are women in remote and underserved communities.

                </p>
                <p>
                    The future is bright for Us and we are expanding our reach fast.

                </p>
            </div>
            <div>

            </div>
        </div>
    </>
}

export default Impact;