import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LogoComponent from "../components/LogoComponent";
import ModalRootComponent from "../components/modals/ModalRootComponent";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ButtonComponent from "../components/ButtonComponent";
import Logo from "../assets/img/logo.svg"

let VerificationSuccess = () => {
    return <>
        <div>
            <ModalRootComponent
                children={
                    <div className="flex flex-col mulish-font bg-white items-center p-10 justify-center gap-6">
                        <div className="flex justify-center">
                           <LogoComponent></LogoComponent>
                        </div>
                        <div className=" drop-shadow-2xl p-6 rounded-full ">

                            <FontAwesomeIcon className="text-6xl text-rouzo-base-color animate-bounce" icon={faThumbsUp}></FontAwesomeIcon>
                        </div>


                        <p className="text-2xl text-rouzo-base-color">Email verified successfully</p>
                        <Link to={"/login"}>
                            <ButtonComponent text="Login"></ButtonComponent>
                        </Link>
                    </div>
                }
            ></ModalRootComponent>
        </div>
    </>
}

export default VerificationSuccess;