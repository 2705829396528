import BalanceCardComponent from "../../components/BalanceCardComponent"
import CTAButtonComponent from "../../components/CTAButtonComponent"
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import TransactionHistoryComponent from "../../components/TransactionHistoryComponent";
import NewSavingPlan from "../../components/modals/NewSavingPlan";
import { useContext, useEffect, useState } from "react";
import SavingPlansList from "../../components/savings/SavingPlansList";
import { motion } from "framer-motion";
import { AccountContext } from "../../context/AccountContext";
import WithdrawalComponent from "../../components/modals/WithdrawalComponent";
import PortfolioListComponent from "../../components/investments/PortfolioListComponent";
import InvestmentHistoryComponent from "../../components/investments/InvestmentHistoryComponent";
import PortfolioSummaryComponent from "../../components/investments/PortfolioSummaryComponent";
import NotificationComponent from "../../components/NotificationComponent";
import Constants from "../../constants/Constants";
import InvestmentList from "../../components/investments/InvestmentList";
import FormatMoneyComponent from "../../components/misc/FormatMoneyComponent";

let InvestmentDashboard = () => {
    let { stashBalance , funds, investmentHistory, activeInvestments, investor, activeInvestmentBalance} = useContext(AccountContext);
    let [showPortSummary, setShowPortSummary] = useState(false);
    let { selectedPortfolio, setSelectedPortfolio } = useContext(AccountContext);
    let handlePortSum = () => {
        setShowPortSummary(true)
    }
    useEffect(() => {
       
        
        if (selectedPortfolio > 0) {
            setShowPortSummary(true);
        }
        else {
            setShowPortSummary(false);
        }
    }, []);
    return <>
        <div className={`pt-9 pl-10 pr-10 mulish-font flex-grow `}>
            <motion.div
                className='flex-grow'
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
            >

                <div className='flex flex-row justify-between mb-10 w-full'>
                    <div className=''>
                        <p className='mulish-font font-medium text-[32px] text-rouzo-text-color-2 mb-3'>Invest</p>
                        <div>
                            <p className='text-base text-rouzo-text-color font-medium'>Your Path to Wealth</p>
                        </div>
                    </div>
                    <div>
                        <NotificationComponent></NotificationComponent>
                    </div>
                </div>
                <div className="md:grid md:grid-cols-2 mb-10">
                    <BalanceCardComponent balanceText={"Active Investments Balance"} amount={activeInvestmentBalance ? <FormatMoneyComponent amount={Number(activeInvestmentBalance)}></FormatMoneyComponent> : 0} earningsValue={0.00}></BalanceCardComponent>
                    {/*<div className="flex items-center space-x-6 justify-center">
                        <CTAButtonComponent onClick={() => setWithdrawalModalActive(true)} icon={faArrowUp} text="Withdraw"> </CTAButtonComponent>
                        <CTAButtonComponent onClick={() => setNewSavingPlanModalActive(true)} icon={faArrowDown} text="Save to Invest"> </CTAButtonComponent>

                    </div>*/}
                </div>

                <div className="">
                    <div className="mb-7">
                        <p className="text-rouzo-text-color-2 text-sm font-semibold text-xl mb-10">Explore</p>
                        <PortfolioListComponent data={Constants.PORTFOLIOS}></PortfolioListComponent>
                    </div>
                    <div>
                        <p className="text-rouzo-text-color-2 text-sm font-semibold text-xl mb-10">My investments</p>
                        <InvestmentList investments={investmentHistory ? investmentHistory : []}></InvestmentList>
                    </div>

                </div>
                

            </motion.div>

        </div>

    </>
}

export default InvestmentDashboard;