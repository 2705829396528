import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import AuthService from "../api/services/AuthService";
import { useAuthContext } from "../hooks/useAuth";
const useOnboard = () => {
    const navigate = useNavigate();
    const { dispatch } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isAborted, setIsAborted] = useState(false);
    const [error, setError] = useState(null);

    const onboard = async (userdata) => {
        setIsLoading(true);
        setError(null);
        console.log(userdata);
        try {
            const res = await AuthService.onboard(userdata);
            console.log("Response should be made with : ", res);
            let { type, errors, msg, status } = res;

            if (status === "success") {
                let { authorization, data } = res;
                console.log(data)
                //console.log(authorization);
                //localStorage.setItem("rouzo2.0_token", authorization.token);
                //localStorage.setItem("rouzo2.0_user", JSON.stringify(data))
                dispatch({
                    type: "LOGIN",
                    payload: res.data
                });
                toast.success(`${res.message}`);
                /*setInterval(() => {
                    navigate("/dashboard/home")
                }, 2000);*/
                //fetchAllAccounts();

            }
            console.log(res)
            if (status == "error") {
                let { statusCode, message } = res;

                console.log(message);
                if (message.constructor.name == "String") {
                    toast.error(message)
                }
                else if (message.constructor.name == "Object") {
                    Object.keys(message).forEach(msg => {
                        toast.error(message[msg][0])
                    })
                }
                return ;

                //alert(msg);
            }
            //setIsLoading(false);

            /*if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }*/
        } catch (error) {
            //setIsLoading(false);
            //##TODO: ERROR TOAST

            //errorToast(error.message);
            let { response } = error;
            if (response) {
                let { data } = response;
                if (data) {
                    if (data.message) {
                        toast.error(data.error);
                    }
                }
            }
            else {
                toast.error("An error occured");
            }

            console.log(error)
        }
    };

    useEffect(() => {
        return () => {
            setIsAborted(true);
        };
    }, []);

    return {
        isLoading,
        error,
        onboard,
    };
};

export default useOnboard;
