import { useEffect, useState } from "react";
import InputComponent from "../../components/InputComponent";
import InputComponent3 from "../../components/InputComponent3";
import ButtonComponent from "../../components/ButtonComponent";
import useAccounts from "../../hooks/useAccount";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import NavBackComponent from "../../components/misc/NavBackComponent";



let SettingsPage = ({ investor = null }) => {
    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [mobileNumber, setMobileNumber] = useState("");
    let {updateAccount} = useAccounts();

    let handleFirstName = (e) => {
        setFirstName(e.target.value);
    }

    let handleEditAccount =async () => {
        console.log("clcikcc");
        if(firstName || lastName || mobileNumber && investor){
            /*return console.log({
                f_name : firstName,
                l_name : lastName,
                phone : mobileNumber,
                userId : investor.userId
            })*/
            await updateAccount({
                f_name : firstName ? firstName : investor.name ? investor.name.split(" ")[0] : "",
                l_name : lastName ? lastName : investor.name ? investor.name.split(" ")[1] : "",
                phone : mobileNumber ?  mobileNumber : investor.phone ? investor.phone : "",
                userId : investor.userId
            })
        }
        else {
            toast.error("No change was made.")
            //console.log("NO changes made")
        }
       
    }

    useEffect(() => {
        if (investor) {
            setFirstName(investor.name ? investor.name.split(" ")[0] : "");
            setLastName( investor.name ? investor.name.split(" ")[1] : "");
            setMobileNumber(investor.phone ? investor.phone : "");
        }
    }, [])
    return <>
        <div className="pt-9 pl-10 pr-10 mulish-font flex-grow ">
            <div className="mb-4">
                <NavBackComponent title={"Settings"}></NavBackComponent>
            </div>
            <div className="flex flex-col gap-4">
                <div className="p-4 border border-rouzo-text-color-2 rounded-xl">
                    <p className="font-medium text-xl mb-4">Profile Settings</p>
                    <div className="grid gap-4">
                        <InputComponent3 onChange={(e) => setFirstName(e.target.value)} value={firstName ? firstName : investor.name ? investor.name.split(" ")[0] : ""} placeholder={"First name"}></InputComponent3>
                        <InputComponent3 onChange={(e) => setLastName(e.target.value)} value={lastName ? lastName : investor.name ? investor.name.split(" ")[1] : ""} placeholder={"Last name"}></InputComponent3>
                        <InputComponent3 onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber ? mobileNumber : investor.phone ? investor.phone : ""} placeholder={"Phone number"}></InputComponent3>
                        <div className="flex justify-center w-full ">
                            <ButtonComponent extra={{onClick : () => handleEditAccount()}} text={"Save Changes"}></ButtonComponent>
                        </div>
                    </div>
                </div>
                <div className="p-4 border border-rouzo-text-color-2 rounded-xl">
                    <p className="font-medium text-xl mb-4">Bank Verification</p>
                    {/*<div className="grid gap-4">
                        <InputComponent3 onChange={(e) => setFirstName(e.target.value)} value={firstName ? firstName : investor.name ? investor.name.split(" ")[0] : ""} placeholder={"First name"}></InputComponent3>
                        <InputComponent3 onChange={(e) => setLastName(e.target.value)} value={lastName ? lastName : investor.name ? investor.name.split(" ")[1] : ""} placeholder={"Last name"}></InputComponent3>
                        <InputComponent3 onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber ? mobileNumber : investor.phone ? investor.phone : ""} placeholder={"Phone number"}></InputComponent3>
                        <div className="flex justify-center w-full">
                            <ButtonComponent text={"Save Changes"}></ButtonComponent>
                        </div>
                    </div>*/}
                    <div className="items-center flex justify-center">
                        <div className="border p-4 flex items-center rounded-xl gap-4">
                            <FontAwesomeIcon className="mulish-font text-sm font-medium text-rouzo-green-light" icon={faWarning}></FontAwesomeIcon>
                            <p className="mulish-font text-sm ">Maintenace updates are being made on our verification channel at the moment.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}


export default SettingsPage;