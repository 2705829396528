import AboutUs2 from "./AboutUs copy"

let AboutUs = () => {
    return <>
        <div>
            <div className="flex flex-col">
              
               <AboutUs2></AboutUs2>
            </div>
        </div>
    </>
}


export default AboutUs