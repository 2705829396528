import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let NavbarButtonComponent = ({ text, goTo, icon, active, flip, onClick }) => {
    let [isActive, setIsActive] = useState(true);
    return <>
        {active ?
            <div onClick={onClick} className="p-3 flex gap-3 active-nav rounded-lg hover:cursor-pointer ">
                <FontAwesomeIcon className="items-center self-center" icon={icon} flip={flip} style={{ color: "white" }} />
                <p className="text-base text-white mulish-font font-bold">{text}</p>
            </div>
            :
            <div onClick={onClick} className="p-3 flex gap-3 text-rouzo-text-color rounded-lg hover:cursor-pointer hover:border-2  hover:text-rouzo-base-color hover:border-rouzo-base-color">
                <FontAwesomeIcon className="items-center self-center " icon={icon} flip={flip} />
                <p className="text-base mulish-font font-bold">{text}</p>
            </div>
        }
        {/*
        <div onClick={onClick} className="p-3 flex gap-3 text-rouzo-text-color rounded-lg hover:cursor-pointer hover:border-2  hover:text-rouzo-base-color hover:border-rouzo-base-color">
            <FontAwesomeIcon className="items-center self-center " icon={icon} flip={flip} />
            <p className="text-base mulish-font font-bold">{text}</p>
        </div>*/}

    </>
}


export default NavbarButtonComponent;
