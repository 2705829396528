

let InputComponent3 = (props) => {
    let {type, placeholder, value, onChange} = props;
    return <>
        <div className="mulish-font ">
            <p className="text-sm font-bold text-rouzo-text-color-2">{placeholder ? placeholder : ""}</p>
            <input  {...props} className="px-[32px] w-full pt-[15px] pb-[12px] font-regular text-base bg-rouzo-light-blue-2 rounded-[7px]" placeholder={placeholder} type={type}></input>
        </div>
    </>
}

export default InputComponent3