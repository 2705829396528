import { useContext, useState } from "react";
import SideBarComponent from "../../components/SideBarComponent"
import PortfolioSummaryComponent from "../../components/investments/PortfolioSummaryComponent"
import Constants from "../../constants/Constants";
import NewInvestment from "../../components/modals/NewInvestment";
import MobileNavBarComponent from "../../components/navbar/MobileNavBarComponent";
import NavBackComponent from "../../components/misc/NavBackComponent";
import { AccountContext } from "../../context/AccountContext";



let GreenAssetFinanceSummary = () => {
    let [isNewInvestmentModalActive, setIsNewInvestmentModalActive] = useState(false);
    let {referralSlug} = useContext(AccountContext);
    let handleInvestModal = () => {
        setIsNewInvestmentModalActive(true);
        console.log("xlixxxx");
    }
    return <>
        <div className="flex w-full h-full mb-20">
            <SideBarComponent theActive={"invest"}></SideBarComponent>
            <div className="px-10 pt-10 flex-grow">
                <div className="mb-4">
                    <NavBackComponent title={"Green Finance Portfolio"}></NavBackComponent>
                </div>
                <PortfolioSummaryComponent riskLevel={"low"}
                    portfolioSize={"140m"}
                    insurancePartner={"Allianz"}
                    pricePerUnit={100000}
                    name={"Green Finance Portfolio"}
                    portfolioDescription={"finances green energy equipment purchase for the businesses and the equipment purchase is used as collateral for the loan."}
                    interestRate={21}
                    onClick={handleInvestModal}
                    numberOfUnits={1000000}></PortfolioSummaryComponent>
                    
                <hr className="h-2 mt-4 mb-0"></hr>
                <div className="mt-4 ">
                    <div className="mb-4">
                        <p className="mulish-font  text-rouzo-green-light font-extrabold text-3xl">Go Green , Grow Green.</p>
                        <p className="mulish-fonttext-sm text-rouzo-text-color-2">Earn <span className="text-rouzo-green-light">100 green points </span> for every user you refer that invest in the Green Asset Portfolio</p>
                    </div>
                    <div className="flex border border-rouzo-base-color-op rounded-xl p-4 mulish-font justify-center items-center gap-4">
                        <div className="flex-col items-center justify-center text-center">
                            <p className="text-2xl font-bold text-rouzo-green-light animate-pulse">0 GP</p>
                            <p className="text-base font-medium text-rouzo-green">Green points</p>
                        </div>
                        <div className="flex-col items-center justify-center text-center">
                            <p className="text-3xl font-bold text-rouzo-green-light animate-pulse">{Constants.nairaString} 0.0</p>
                            <p className="text-sm font-medium text-rouzo-green">100GP = {Constants.nairaString} 1000</p>

                        </div>
                        
                    </div>
                    <p className="p-2 mulish-font rounded-xl text-xs font-medium text-rouzo-text-color-2 border border-dotted ">Referral code : <span className="text-base font-bold">{referralSlug ? referralSlug : ""}</span> </p>
                </div>
            </div>
            {isNewInvestmentModalActive ? <NewInvestment portfolio={Constants.PORTFOLIOS[2]} setIsNewInvestmentModalActive={setIsNewInvestmentModalActive}></NewInvestment> : ""}
        </div>

        <MobileNavBarComponent></MobileNavBarComponent>
    </>
}

export default GreenAssetFinanceSummary