
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faMoon, faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { faCog, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import { useState } from "react";
import { motion } from "framer-motion";
import { useAuthContext } from "../hooks/useAuth";
import { Link } from "react-router-dom";
let NotificationComponent = () => {
    let [userState, setUserState] = useState(false);
    const { dispatch } = useAuthContext();
    let toggleState = () => {
        if (userState == false) {
            setUserState(true);
        }
        else if (userState == true) {
            setUserState(false);
        }
    }

    let handleLogout = () => {
        localStorage.setItem("rouzo2.0_token", "");
        dispatch({
            type: "LOGOUT",
        })
    }
    return <>
        <div className="border-2 px-[20px] py-[8px] border-rouzo-grey flex gap-[20px] rounded-3xl">
            <div className="hover:cursor-pointer hover:scale-150">
                <FontAwesomeIcon icon={faBell} className="text-rouzo-text-color-2 hover:text-black hover:animate-bounce hover:scale-150"></FontAwesomeIcon>
            </div>
            <div className="hover:cursor-pointer hover:scale-150">
                <FontAwesomeIcon icon={faMoon} className="text-rouzo-text-color-2 hover:text-black hover:animate-bounce"  ></FontAwesomeIcon>
            </div>
            <motion.div

                className="hover:cursor-pointer">
                <FontAwesomeIcon icon={faCircleUser} onClick={() => toggleState()} className=" hover:scale-150 text-rouzo-text-color-2 hover:text-black hover:animate-bounce "  ></FontAwesomeIcon>
                {userState ? <div className="rounded-xl p-4 mulish-font border border-rouzo-base-color-op gap-4 flex-col absolute right-14 top-20">
                    <Link to="/dashboard/settings">
                        <div className="mb-2 flex font-medium text-rouzo-tex-color-2 items-center gap-2 hover:cursor-pointer hover:underline hover:underline-offset-2">
                            <FontAwesomeIcon className="text-rouzo-base-color" icon={faCog}></FontAwesomeIcon>
                            <p className="font-bold text-sm text-rouzo-text-color-2">Settings</p>
                        </div>
                    </Link>
                    <hr className="mb-2">
                    </hr>
                    <div onClick={() => handleLogout()} className=" flex font-medium text-rouzo-tex-color-2 items-center gap-2 hover:cursor-pointer hover:underline hover:underline-offset-2">
                        <FontAwesomeIcon  flip="horizontal" icon={faRightFromBracket} className="text-rouzo-base-color" ></FontAwesomeIcon>
                        <p className="font-bold text-sm text-rouzo-text-color-2">Logout</p>
                    </div>
                </div>
                    : " "}
            </motion.div>
        </div>
    </>
}


export default NotificationComponent;