import { Link } from "react-router-dom"
import WomanAlone1 from "../assets/img/inclusion/IEbULWNwAmBHIn12Fy6qjw-1024x1024.webp"
import WomanInYellow from "../assets/img/inclusion/JAq6lmWLFxaHTTtzi2ELYK-2048x2048.webp";
import WomanTrader3Img from "../assets/img/inclusion/Woman trader3.jpg";
import WomanTrader from "../assets/img/inclusion/Woman trader-image5.jpg";
import CustomerImg from "../assets/img/inclusion/Customer-Image12.jpg";
import CustomerImg2 from "../assets/img/inclusion/Woman trader1b.jpg"
let InclusionPage = () => {
    return <>
        <div className="lota-font font-lota">
            <div className="lota-font font-lota flex flex-row justify-between">
                <div>

                </div>
                <div className="py-4 gap-4 flex flex-row pr-8 items-center justify-center">

                    <div>
                        <Link to={"/"}><p>Rouzo</p> </Link>
                    </div>
                    <div>
                        <Link>
                            <p>About</p>
                        </Link>
                    </div>
                    <div>
                        <p>Impact</p>
                    </div>
                    <div>
                        <p>Activities</p>
                    </div>
                </div>
            </div>
            <div className="lota-font font-lota grid grid-cols-2 pl-8 justify-between gap-4 pb-8">
                <div className="flex flex-col gap-4 p-4">
                    <div className="flex flex-col gap-2  pr-4 md:pr-0">

                        <p className="text-xs text-rouzo-base-color">Building Financial Independence and Driving Economic Growth</p>
                        <p className="text-[50px] text-black">Catalyzing financial inclusion for female business owners in Peri-Urban communities</p>
                    </div>
                    <div className="flex flex-row gap-4">
                        <div className="bg-rouzo-base-color text-white py-2 p-4">
                            <Link to={"https://forms.gle/kVuKrv84Miusk4Jb8"}>
                                <p>Join </p>
                            </Link>

                        </div>
                        <div className="outline-2 outline outline-rouzo-deep-blue p-4 py-2 bg-white text-rouzo-deep-blue ">
                            <Link to={"https://paystack.com/pay/rouzoinclusionproject"}>
                                <p>Donate</p>
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="hidden md:flex flex-row  justify-end">
                    <img className="h-[500px] w-3/4 justify-end" src={WomanTrader3Img}></img>
                </div>
            </div>
            <div className="p-4 flex-col flex gap-4 bg-rouzo-deep-blue">
                <div className="text-center flex flex-col p-8 gap-2">
                    <p className="font-bold text-xl text-white">THE PROJECT</p>
                    <p className="text-white ">The Rouzo Empowerment Project is a 6 month-long initiative designed to bridge the financial inclusion gap for women entrepreneurs in peri-urban
                        communities of Lagos and Ogun states. We aim to train 200 women in four key areas:
                    </p>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-4 p-4 font-bold text-white justify-center gap-4">
                    <div>
                        <p>Financial Literacy & Manageent</p>
                    </div>
                    <div>
                        <p>Basic Digital Skills</p>
                    </div>
                    <div>
                        <p>Overcoming Social Constraints</p>
                    </div>
                    <div>
                        <p>Community Development</p>
                    </div>

                </div>
            </div>
            <div className="flex">
                <div className="grid grid-cols-2">
                    <img className="md:flex hidden " src={WomanTrader}></img>
                    <img className="md:flex hidden " src={CustomerImg}></img>
                </div>

                <div className="relative md:absolute right-0 md:w-1/2 md:h-1/2 justify-center text-left gap-4  flex flex-col items-center backdrop-blur-sm md:bg-indigo-100 p-8 md:p-20">
                    <div>
                        <p className="font-bold text-rouzo-deep-blue">Catalyzing Financial Inclusion s</p>
                        <p>Equip women with the skills and resources to achieve financial independence and contribute more effectively to their households and communities</p>
                    </div>
                    <div>
                        <p className="font-bold text-rouzo-deep-blue">Business Growth</p>
                        <p>Equip women with the skills and resources to achieve financial independence and contribute more effectively to their households and communities</p>
                    </div>
                    <div>
                        <p className="font-bold text-rouzo-deep-blue">Community Development</p>
                        <p>contribute to the overall economic development of peri-urban communities by fostering a more inclusive and empowered local business center</p>
                    </div>
                </div>
            </div>
            <div className=" flex flex-col">
                <div className="grid grid-cols-2">
                    <div className="bg-rouzo-deep-blue items-center p-8 md:p-60 text-white flex flex-row justify-center">
                        <p className="font-bold md:text-3xl">Donate</p>
                    </div>
                    <div className="text-center p-8 md:p-60 md:text-2xl flex flex-col justify-center gap-2" >
                        <p>We are raising </p>
                        <p className="text-rouzo-base-color font=bold text-2xl  md:text-[50px]" >$10,000 </p>
                        <p>towards this project. </p>
                        <div className="bg-rouzo-deep-blue text-white px-4 py-1 mt-4">
                            <Link to={"https://paystack.com/pay/rouzoinclusionproject"}>
                                <p>Donate</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className=" flex flex-col">
                <div className="grid grid-cols-2">

                    <div className="text-center p-8 md:p-60 md:text-2xl flex flex-col justify-center gap-2" >
                        <p> Become a volunteer and provide mentorship or workshop facilitation </p>
                        <div className="bg-rouzo-base-color text-white px-4 py-1 mt-4">
                            <Link to={"https://forms.gle/kVuKrv84Miusk4Jb8"}>
                                <p>Volunteer </p>
                            </Link>
                        </div>
                    </div>
                    <div className="bg-rouzo-base-color items-center p-8 md:p-60 text-white flex flex-row justify-center">
                        <p className="font-bold md:text-3xl">Volunteer</p>
                    </div>
                </div>
            </div>
            <div className=" flex flex-col">
                <div className="grid grid-cols-2">
                    <div className="bg-rouzo-green-light items-center p-8  md:p-60 text-white flex flex-row justify-center">
                        <p className="font-bold md:text-3xl">Spread the Word</p>
                    </div>
                    <div className="text-center p-8 md:p-60 md:text-2xl flex flex-col justify-center gap-2" >
                        <p>Promote the project through social media or networks and mobilize your network to support the project.</p>
                    </div>
                </div>
            </div>
            <div className="p-8">
                <div className="bg-rouzo-base-color p-8 rounded-xl flex flex-col text-white gap-2">
                    <p className="text-white text-3xl font-bold ">Stay Connected</p>
                    <p>Follow <span className="font-bold">@rouzoco</span> on Instagram, X(formerly Twitter), Facebook and Rouzo on Linkedin to stay connected.</p>
                    <p>Send an email to hello@rouzo.co for inquiries or partnership opportunities.</p>
                </div>
            </div>

        </div>
    </>
}


export default InclusionPage