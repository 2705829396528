

let InputComponent = (props) => {
    let {type, placeholder, disabled} = props;
    return <>
        <div className="mulish-font " {...props}>
            <input disabled={disabled ? true : false} className="py-[21px] w-full px-[25px] bg-rouzo-blue-light rounded-xl" placeholder={placeholder} type={type} ></input>
        </div>
    </>
}

export default InputComponent