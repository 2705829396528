import { useContext, useEffect, useState } from "react";
import InputComponent from "../InputComponent";
import CTAButtonComponent from "../CTAButtonComponent";
import { AnimatePresence, motion } from "framer-motion";
import ModalRootComponent from "./ModalRootComponent";
import Constants from "../../constants/Constants";
import { faCreditCard, faBuildingColumns, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import SelectComponent from "../SelectComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import SavingsService from "../../api/services/SavingsService";
import { AccountContext } from "../../context/AccountContext";
import useAccounts from "../../hooks/useAccount";


let NewSavingPlan = ({ setNewSavingPlanModalActive }) => {
    let [currentPage, setCurrentPage] = useState(1);
    let { investor } = useContext(AccountContext);
    let {fetchProfile} = useAccounts()
    let [savingPlanName, setSavingPlanName] = useState("");
    let [savingPlanAmount, setSavingPlanAmount] = useState(0);
    let [savingPlanLength, setSavingPlanLength] = useState("3");
    let [savingPlanFrequency, setSavingPlanFrequency] = useState("daily");


    let page = {
        back: () => {
            if (currentPage == 1) {
                console.log(currentPage);
                console.log("we should gp back");
                console.log(setNewSavingPlanModalActive)
                setNewSavingPlanModalActive(false);
            }
            if (currentPage > 1) {
                setCurrentPage(currentPage => currentPage - 1);
            }

        },
        next: () => {
            if (currentPage < 5) {
                setCurrentPage(currentPage => currentPage + 1);
            }
        }
    }
    let handleProceedPayment = async () => {
        if (!savingPlanAmount) {
            setCurrentPage(2);
            return toast.error("You missed your saving plan amount")
        }
        if (!savingPlanFrequency) {
            setCurrentPage(4);
            return toast.error("You missed to set how often you want to save")
        }
        if (!savingPlanLength) {
            setCurrentPage(3);
            return toast.error("You missed setting up how long you want to save for");
        }
        if (savingPlanAmount && savingPlanFrequency && savingPlanLength) {
            page.next();

        }
    }

    let pay = async () => {
        console.log(investor);
        if(!investor){
            await fetchProfile();
        }
        console.log({
            email: investor.email,
            type: "crd",
            name: savingPlanName,
            amount: savingPlanAmount,
            interval: savingPlanFrequency,
            span: savingPlanLength
        })

        if(savingPlanAmount < 100){
            toast.error(`Minimum saving amount is ${Constants.nairaString}100`);
            return;
        }

        let newSaving = await SavingsService.create({
            email: investor.email,
            type: "crd",
            name: savingPlanName,
            amount: savingPlanAmount,
            interval: savingPlanFrequency,
            span: savingPlanLength
        })
        console.log("New saving::  ", newSaving);
        if(newSaving){
            if(newSaving.type == "success"){
                toast.success("Redirecting to payment gateway shortly to initiate your saving process.");
                let {url} = newSaving.data;
                console.log(url);
                setTimeout(()=>{
                    window.location = url;
                }, 3000);
            }
        }
    }
    let handleSavingPlanAmount = (e) => {
        setSavingPlanAmount(e.target.value);
    }
    let handleSavingPlanName = (e) => {
        setSavingPlanName(e.target.value);
    }
    let handleSavingPlanLength = (e) => {

        setSavingPlanLength(e.target.value);
        console.log("SavingPlanLength: ", savingPlanLength);
    }
    let handleSavingPlanFrequency = (e) => {
        setSavingPlanFrequency(e.target.value);
        console.log("SavingPlanFreq: ", savingPlanFrequency);
    }
    let handlePageSwitch = () => {
        console.log("page switch");
        console.log(currentPage);
        switch (currentPage) {
            case 1:
                return <div>
                    <p className="text-rouzo-text-color-2 mulish-font font-bold text-[32px] mb-6">Create new saving plan</p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="mb-[100px]">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">Saving title</p>
                            <InputComponent value={savingPlanName} onChange={(e) => handleSavingPlanName(e)}></InputComponent>
                        </div>
                    </motion.div>


                </div>
            case 2:
                return <div>
                    <p className="text-rouzo-text-color-2 font-bold text-[32px] mb-6">Create new saving plan</p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="mb-[100px] mulish-font">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How much do you want to save?</p>
                            <InputComponent value={savingPlanAmount} onChange={(e) => handleSavingPlanAmount(e)} placeholder={"₦ 100000 min : ₦100"} type="number" ></InputComponent>
                        </div>

                    </motion.div>

                </div>
            case 3:
                return <div>
                    <p className="text-rouzo-text-color-2 mulish-font font-bold text-[32px] mb-6">Create new saving plan</p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="mb-6 mulish-font">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How much do you want to save?</p>
                            <InputComponent disabled >₦{savingPlanAmount}</InputComponent>
                        </div>
                        <div className="mb-[100px]">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How long do you want to save for?</p>
                            <SelectComponent onChange={(e) => handleSavingPlanLength(e)} data={Constants.SAVINGS.PLANS_LENGTH} ></SelectComponent>
                        </div>

                    </motion.div>

                </div>;
            case 4:
                return <div>
                    <p className="text-rouzo-text-color-2 mulish-font font-bold text-[32px] mb-6">Create new saving plan</p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="mb-6 mulish-font">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How much do you want to save?</p>
                            <InputComponent disabled >{savingPlanAmount}</InputComponent>
                        </div>
                        <div className="mb-6">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How long do you want to save for?</p>
                            <SelectComponent disabled data={[{ text: savingPlanLength }]} ></SelectComponent>
                        </div>
                        <div className="mb-6">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How often do you want to save?</p>
                            <SelectComponent onChange={(e) => handleSavingPlanFrequency(e)} data={Constants.SAVINGS.PLANS_INTERVAL}></SelectComponent>
                        </div>

                    </motion.div>

                </div>;
            case 5:
                return <div>
                    <p className="text-rouzo-text-color-2 mulish-font font-bold text-[32px] mb-6">Pay ₦{savingPlanAmount} using : </p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="flex flex-col space-y-6">
                            <div onClick={() => pay()} className="flex space-x-[12px] bg-rouzo-blue-light p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-pointer hover:border-rouzo-base-color">
                                <FontAwesomeIcon icon={faCreditCard} className="text-rouzo-text-color-2"></FontAwesomeIcon>
                                <p className="text-sm font-semibold">Card</p>
                            </div>
                            <div onClick={() => pay()} className="flex space-x-[12px] bg-rouzo-blue-light p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-pointer hover:border-rouzo-base-color">
                                <FontAwesomeIcon icon={faMoneyBillTransfer} className="text-rouzo-text-color-2"></FontAwesomeIcon>
                                <p className="text-sm font-semibold">Transfer</p>
                            </div>
                            <div onClick={() => pay()} className="flex space-x-[12px] bg-rouzo-blue-light p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-pointer hover:border-rouzo-base-color">
                                <FontAwesomeIcon icon={faBuildingColumns} className="text-rouzo-text-color-2"></FontAwesomeIcon>
                                <p className="text-sm font-semibold">Bank</p>
                            </div>
                            <div className="flex space-x-[12px] bg-rouzo-grey p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-cancel hover:border-rouzo-base-color">
                                <p className="text-sm font-semibold">Rouzo Stash</p>
                                <p className="text-right text-green-400 text-xs p-1 border border-green-400 rounded-2xl">COMING SOON</p>
                            </div>
                        </div>

                    </motion.div>
                </div>
        }
    }
    return <>
        <ModalRootComponent
            children={
                <div className="z20 mulish-font rounded-2xl self-center p-6 pt-9 bg-white">
                    <div onClick={page.back} className="hover:cursor-pointer text-rouzo-text-color-2 font-medium text-xl">
                        <p>{'<'} back</p>
                    </div>
                    <div>
                        {handlePageSwitch()}
                    </div>
                    <div className="text-right flex justify-end ">
                        <div >
                            {
                                currentPage < 4 ?
                                    <CTAButtonComponent onClick={page.next} text={(currentPage < 4) ? "Continue" : "Proceed to Payment"}></CTAButtonComponent>
                                    : ""
                            }
                            {
                                currentPage == 4 ?
                                    <CTAButtonComponent onClick={handleProceedPayment} text={"Proceed to Payment"}></CTAButtonComponent>
                                    : ""
                            }



                        </div>

                    </div>
                </div>
            }
        ></ModalRootComponent>
    </>

}


export default NewSavingPlan;