import CTAButtonComponent from "./CTAButtonComponent";


let CTACardComponent = ({ cardHeaderText, cardBodyText, ctaButtonGoTo, ctaButtonText, backgroundRepeat, backgroundImage, backgroundPosition }) => {

    return <>
        <div style={{
            backgroundRepeat : backgroundRepeat,
            backgroundImage: `url('${backgroundImage}')`,
            backgroundPosition: backgroundPosition
        }}
            className="mulish-font p-6 bg-rouzo-blue-light rounded-[20px]">
            <p className="font-bold text-xl text-rouzo-text-color-2 mb-4">{cardHeaderText}</p>
            <p className="font-medium text-base text-[#7D87A7] mb-16"> {cardBodyText} </p>

            <div className="grid grid-cols-2">
                <CTAButtonComponent text={ctaButtonText} goTo={ctaButtonGoTo}></CTAButtonComponent>
            </div>


        </div>
    </>
}


export default CTACardComponent;