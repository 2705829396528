import BalanceCardComponent from '../../components/BalanceCardComponent';
import CTAButtonComponent from '../../components/CTAButtonComponent';
import CTACardComponent from '../../components/CTACardComponent';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { AccountContext } from '../../context/AccountContext';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import useAccounts from '../../hooks/useAccount';
import { motion } from "framer-motion";
import WithdrawalComponent from '../../components/modals/WithdrawalComponent';
import FundStashComponent from '../../components/modals/FundStashComponent';
import NotificationComponent from '../../components/NotificationComponent';
import investmentSvg from "../../assets/img/investment.svg";
import piggyBankSvg from "../../assets/img/piggybank.svg";
import FormatMoneyComponent from '../../components/misc/FormatMoneyComponent';


let HomeDashboard = () => {
    const { investor, stashBalance, availableAmount } = useContext(AccountContext);
    const { fetchProfile, } = useAccounts();
    let [isWithdrawalModalActive, setWithdrawalModalActive] = useState(false);
    let [isFundingModalActive, setFundingModalActive] = useState(false);
    //fetchProfile();
    useEffect(() => {
        //!accounts ? fetchAllAccounts() : "";
        console.log(investor);
        if (Object.keys(investor).length == 0) {
            console.log("Fetching profile");
            console.log(investor);
            fetchProfile()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investor]);
    return <>
        {isWithdrawalModalActive ? <WithdrawalComponent setIsModalActive={setWithdrawalModalActive} ></WithdrawalComponent> : ""}
        {isFundingModalActive ? <FundStashComponent setIsModalActive={setFundingModalActive} ></FundStashComponent> : ""}

        <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            className='pt-9 pl-10 pr-10 mulish-font flex-grow'>
            <div className='flex flex-row justify-between mb-10 w-full'>
                <div className=''>
                    <p className='mulish-font font-medium text-[32px] text-rouzo-text-color-2 mb-3'>Welcome back, {investor.name}</p>
                    <div>
                        <p className='text-base text-rouzo-text-color font-medium'>Home>Dashboard</p>
                    </div>
                </div>
                <div>
                    <NotificationComponent></NotificationComponent>
                </div>
            </div>
            {/*Overview and Portfolio div */}
            <div className='bg-rouzo-grey p-1.5 flex rounded-full max-w-[256px] mb-10'>
                <div className='rounded-full px-[26.5px] bg-white text-rouzo-text-color-2 p-[13px]' >
                    <p className='text-sm font-semibold'>Overview</p>
                </div>

                <Link to={"/dashboard/invest"}>
                    <div className='px-[26.5px] text-rouzo-text-color-2 p-[13px]' >
                        <p className='text-sm font-semibold hover:underline hover:underline-offset-2'>Portfolio</p>
                    </div>
                </Link>

            </div>

            {/** */}
            <div className='grid grid-cols-1 md:grid-cols-2 mb-10'>
                <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className='mb-3'
                >
                    <BalanceCardComponent balanceText={"Your Balance"}
                        amount={availableAmount ? <FormatMoneyComponent amount={availableAmount}></FormatMoneyComponent> : 0}
                        earningsValue={0.0}>

                    </BalanceCardComponent>
                </motion.div>
                <div className='flex gap-6 items-center justify-center gap-6'>
                    <CTAButtonComponent onClick={() => setFundingModalActive(true)} icon={faArrowUp} rotation={180} text="Fund Stash" ></CTAButtonComponent>
                    <CTAButtonComponent onClick={() => setWithdrawalModalActive(true)} icon={faArrowUp} rotation={45} text="Withdraw"></CTAButtonComponent>

                </div>
            </div>

            {/*Save and Invest*/}
            <div className='grid  md:grid-cols-2 gap-6'>
                <Link to={"/dashboard/save"}>
                    <CTACardComponent ctaButtonGoTo={""} backgroundImage={piggyBankSvg} backgroundRepeat={"no-repeat"} backgroundPosition={"right bottom"} ctaButtonText={"Quicksave"} cardBodyText={"Start small and watch your savings grow over time"} cardHeaderText={"Save Small, Grow Big"}></CTACardComponent>
                </Link>
                <Link to={"/dashboard/invest"}>
                    <CTACardComponent ctaButtonGoTo={""} backgroundImage={investmentSvg} backgroundRepeat={"no-repeat"} backgroundPosition={"right bottom"} ctaButtonText={"Invest now"} cardBodyText={"Investment: Your Path to Wealth"} cardHeaderText={"Let’s help you build wealth"}></CTACardComponent>
                </Link>

            </div>

        </motion.div>

    </>
}


export default HomeDashboard