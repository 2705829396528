import MobileNavItem from "./MobileNavItem";
import { faChartLine, faHouse, faPiggyBank,faLeaf , faCog} from "@fortawesome/free-solid-svg-icons";


let MobileNavBarComponent = () => {
    return <>
        <div className="md:hidden fixed grid grid-cols-5 bottom-0 bg-white z-20 w-full p-4 ">
            <MobileNavItem to={"/dashboard/invest"} text={"Invest"} icon={faChartLine}></MobileNavItem>
            <MobileNavItem to={"/dashboard/save"} text={"Save"} icon={faPiggyBank}></MobileNavItem>
            <MobileNavItem to={"/dashboard/home"} text={"Home"} icon={faHouse}></MobileNavItem>
            <MobileNavItem to={"/dashboard/portfolio/green-asset-finance/summary"} className={"animate-pulse"} customColor={"rouzo-green"} text={"Gogreen"} icon={faLeaf}></MobileNavItem>
            <MobileNavItem to={"/dashboard/settings"} text={"Settings"} icon={faCog}></MobileNavItem>
        </div>
    </>
}


export default MobileNavBarComponent;