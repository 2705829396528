import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../../constants/Constants";
import { faPiggyBank,faChartLine } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";


let InvestmentComponent = ({id, example,portfolioId, amount, isOpen}) => {
    return <>
        <Link to={`/dashboard/invest/${id}`}>
            <div className={`w-full px-6 py-[18px] mulish-font rounded-xl bg-rouzo-blue-light flex ${example ? "animate-pulse" : ""}`}>
                <div className="flex space-x-4">
                    <FontAwesomeIcon className="items-center self-center text-4xl" flip="horizontal" icon={faChartLine} style={{ color: "#2709BB" }} />
                    <div className="flex flex-col">
                        <p className="text-rouzo-text-color-2 font-bold text-base">{Constants.PORTFOLIOS.filter(o => o.db_id == portfolioId)[0].name} - #{id}</p>
                        <p className="font-bold text-[14px] text-rouzo-text-color"> ₦ {amount}</p>
                    </div>
                </div>
            </div>
        </Link>
    </>
}

export default InvestmentComponent;