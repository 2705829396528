import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../context/AccountContext";
import UserService from "../api/services/UserService";
import { toast } from "react-toastify";
import AccountService from "../api/services/AccountService";

const useAccounts = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [isAborted, setIsAborted] = useState(false);

    const [error, setError] = useState(null);

    const { userId, setUserId } = useContext(AccountContext);

    const { investor, setInvestor } = useContext(AccountContext);
    const { investmentHistory, setInvestmentHistory } = useContext(AccountContext);
    const { stashBalance, setStashBalance } = useContext(AccountContext);
    const { availableAmount, setAvailableAmount } = useContext(AccountContext);
    const { transactions, setTransactions } = useContext(AccountContext);
    const { transfers, setTransfers } = useContext(AccountContext);
    const { purchase, setPurchase } = useContext(AccountContext);
    const { savings, setSavings } = useContext(AccountContext);
    const { isStash, setIsStash } = useContext(AccountContext);
    const { isAllowed, setIsAllowed } = useContext(AccountContext);
    const { funds, setFunds } = useContext(AccountContext);
    const {referralSlug , setReferralSlug} = useContext(AccountContext);
    const {userDetails, setUserDetail} = useContext(AccountContext);
    const {activeInvestments, setActiveInvestments} = useContext(AccountContext);
    const {activeInvestmentBalance, setActiveInvestmentBalance} = useContext(AccountContext);

    const token = localStorage.getItem("rouzo2.0_token");
    //should for fetch

    const fetchProfile = async () => {
        try {
            const res = await UserService.fetchProfile(token);
            const { type, errors, status } = res;
            if (status === "success") {
                let { data } = res;
                console.log(res.data.investor);
                setInvestor(res.data.investor);
                setStashBalance(res.data.balance);
                setAvailableAmount(res.data.availableAmt);
                setTransactions(data.transactions.data);
                setTransfers(data.transfers);
                setSavings(data.savings);
                setPurchase(data.purchase);
                setUserId(data.investor.userId)
                setInvestmentHistory(data.investments)
                setFunds(data.funds);
                setReferralSlug(data.referralSlug);
                setUserDetail(data.user)
                setActiveInvestments(data.activeInvestments);
                let _activeInvestmentBalance = data.activeInvestments.reduce((a,b)=> {return a + Number(b.amount)}, 0);
                console.log(_activeInvestmentBalance)
                setActiveInvestmentBalance(_activeInvestmentBalance);
            }
            if (status === "error") {
                let { statusCode, msg } = res;
                if (statusCode === 205) {
                    //##TODO ALERT
                    //navigate("/onboard");
                }
                else {
                    console.log(msg);
                    alert(msg);
                }
            }
            setIsLoading(false);

            if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }
        } catch (error) {
            setIsLoading(false);
            //##TODO: ERROR TOAST
            //errorToast(error.message);
            console.log(error)
            if (error.response && error.response.status == 401) {
                toast.error("Session expired. Please do login");
                setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            if (!isAborted) {
                console.log(error, "error");
                setIsLoading(false);
            }
        }
    }

    const updateAccount = async (data) => {
        try {
            const res = await AccountService.updateAccount(data);
            const { type, errors, status } = res;
            if (status === "success") {
                let { data } = res;
                //console.log(res.data.investor);
                fetchProfile()
                toast.success(res.message ? res.message : res.mg);
            }
            if (status === "error") {
                let { statusCode, msg, message } = res;


                console.log(msg);
                toast.error(msg ? msg : message);

            }
            setIsLoading(false);

            if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }
        }
        catch (e) {
            console.log(e);

            if (e.response && e.response.status == 401) {
                toast.error("Session expired. Please do login");
                return setTimeout(() => {
                    navigate("/login");
                }, 2000)
            }
            return toast.error(e.message ? e.message : e)
        }
    }
    useEffect(() => {
        return () => {
            setIsAborted(true);
        };
    }, []);

    return {
        isLoading,
        error,
        fetchProfile,
        updateAccount,
        investor,
        stashBalance, setStashBalance,
        availableAmount, setAvailableAmount,
        transactions, setTransactions,
        transfers, setTransfers,
        purchase, setPurchase,
        savings, setSavings,
        isStash, setIsStash,
        isAllowed, setIsAllowed,
        funds,setFunds,
        userDetails, setUserDetail,
        referralSlug , setReferralSlug,
        activeInvestmentBalance, setActiveInvestmentBalance
        //banks
    };
};

export default useAccounts;
