
import noRecord from "../assets/img/no_record.svg";

let TransactionHistoryComponent = ({ data }) => {


    return <>
        <div className="rounded-md drop-shadow-rouzo-tran-shad h-screen border mulish-font min-h-full w-full md:min-w-[50%]">
            <div className="p-5 pl-9 border-b">
                <p className="text-rouzo-text-color-2 font-normal">Transaction History</p>
            </div>
            {data ? "" :
                <div className=" flex flex-col items-center self-center pt-28 justify-center">
                    <img src={noRecord}></img>
                    <p className="text-base font-medium">No transaction record</p>
                </div>
            }
        </div>
    </>
}


export default TransactionHistoryComponent