import React, { useContext, useEffect, useState } from "react";

import ButtonComponent from "../../components/ButtonComponent";
import InputComponent from "../../components/InputComponent";
import LogoComponent from "../../components/LogoComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import useSignIn from "../../hooks/useLogin";
import { OnboardContext } from "../../context/OnboardContext";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/img/Group 55865.png"

let Login = () => {
    const navigate = useNavigate();

    const { signIn } = useSignIn();
    const { emailAddress, setEmailAddress } = useContext(OnboardContext);
    const { mobileNumber, setMobileNumber } = useContext(OnboardContext);
    const {password , setPassword} = useContext(OnboardContext);
    const { setOtpFor } = useContext(OnboardContext);

    const [validMail, setValidMail] = useState(false);
    const [validMobileNumber, setValidMobileNumber] = useState(false);

    let [isLoading, setIsLoading] = useState(false);

    const handlePassword = (e) => {
        setPassword(e.target.value);
    }
    const handleInputChange = (e) => {
        console.log(e.target.value);
        let { value } = e.target;
        if (/^[0-9]+$/.test(value) && value.length <= 11) {
            if (value.length == 11) {
                console.log("Valid number")
                //setEmailAddress("");
                setValidMobileNumber(true);
            }
            else (setValidMobileNumber(false));
            setMobileNumber(e.target.value);
        }
        else {
            if (mobileNumber.length === 11) return setMobileNumber(mobileNumber);
            setMobileNumber("");
        }
        if (!/^[0-9]+$/.test(value) && /[a-z]|[A-Z]|[0-9]|[\+.]/.test(value)) {
            if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(emailAddress)) {
                console.log("Valid mail");
                setValidMail(true);
            }
            else setValidMail(false)
            if (!validMobileNumber) setEmailAddress(e.target.value)
        }
        else {
            setEmailAddress("")
        }
    }
    const handleLogin = async () => {
       
        //setting otpFor Context to be used by the confirmotp component
        if(isLoading == true) {
            return;
        }
        console.log("Logging In");
        setIsLoading(true);
        setOtpFor("login");
        let adjustedMobileNumber = "";
        console.log("Current MobileNumber:  ", mobileNumber);
        //switch to nigeria code
        if(mobileNumber[0] == 0){
            console.log("First number is 0, converting to ngn country code");
            adjustedMobileNumber = `+234${mobileNumber.substring(1,mobileNumber.length)}`;
            console.log(adjustedMobileNumber)
        }
        //return
        await signIn({
            email: emailAddress,
            password : password
        })
        setIsLoading(false);
    }
    useEffect(() => {

    }, [])

    return <>
        <ToastContainer />
        <div className="flex flex-col md:grid items-center md:grid-cols-2 ">
            <div style={{background : `url('${backgroundImage}')`, backgroundRepeat : 'no-repeat', backgroundSize : 'cover'}}  className="hidden md:flex md:visible invisible bg-center bg-contain bg-no-repeat flex justify-center bg-rouzo-blue-light align-middle h-screen items-center ">
                <div className="self-center align-middle items-center">
                    <LogoComponent></LogoComponent>
                </div>
            </div>
            <div className="self-center md:hidden  align-middle items-center">
                    <LogoComponent></LogoComponent>
                </div>
            <div className="p-6 h-screen md:h-full md:p-[97px] md:py-[112px] mulish-font">
                <div className=" mb-6">
                    <p className="text-rouzo-text-color-2 mb-6 font-semibold text-[32px]"> Welcome to Rouzo</p>
                    <p className="text-rouzo-text-color font-regular text-xl">Login to your account</p>
                </div>
                <div className="p-10 space-y-5 border mb-6">
                    <div className="space-y-5">
                        <InputComponent onChange={handleInputChange}  placeholder={"example@gmail.com"}></InputComponent>
                        <InputComponent onChange={handlePassword} type="password"  placeholder={"********"}></InputComponent>
                    </div>
                    <p className="text-right text-rouzo-base-color">Forgot password?</p>
                    <div>
                        <ButtonComponent isLoading={isLoading} text={"Login"} extra={{onClick : handleLogin}}></ButtonComponent>
                    </div>
                </div>
                <div className="">
                    <p onClick={() => navigate("/onboard")} className="rouzo-grey ">Don't have an account? <span className="text-rouzo-base-color hover:underline hover:underline-offset-2 hover:cursor-pointer">Register</span></p>
                </div>
               
            </div>
        </div>
    </>
}


export default Login;