import { useState, React, createContext } from "react";


export const AccountContext = createContext();

// Create an exportable consumer that can be injected into components
export const AccountConsumer = AccountContext.Consumer
// Create the provider using a traditional React.Component class

const AccountProvider = ({children}) => {
    let [investmentHistory, setInvestmentHistory] = useState([]);
    let [savingHistory, setSavingHistory] = useState([]);
    let [userDetail, setUserDetail] = useState({});
    let [userId, setUserId] = useState("");
    let [investor, setInvestor] = useState({});
    let [stashBalance , setStashBalance] = useState(0);
    let [availableAmount , setAvailableAmount] = useState(0);
    let [transactions, setTransactions] = useState([]);
    let [transfers, setTransfers] = useState({});
    let [purchase , setPurchase] = useState({});
    let [savings , setSavings] = useState({});
    let [isStash , setIsStash] = useState(false);
    let [isAllowed, setIsAllowed] = useState(false);
    let [selectedPortfolio, setSelectedPortfolio] = useState(0);
    let [investmentUnit, setInvestmentUnit] = useState(1);
    let [investmentPlanAmount, setInvestingPlanAmount] = useState(0);
    let [funds, setFunds] = useState(0);
    let [referralSlug , setReferralSlug] = useState("");
    let [activeInvestments, setActiveInvestments] = useState([]);
    let [activeInvestmentBalance, setActiveInvestmentBalance] = useState(0);
   
    return (
        // value prop is where we define what values 
        // that are accessible to consumer components
        <AccountContext.Provider value={{
                investmentHistory, setInvestmentHistory,
                savingHistory, setSavingHistory,
                userDetail, setUserDetail,
                userId, setUserId,
                investor, setInvestor,
                stashBalance, setStashBalance,
                availableAmount, setAvailableAmount,
                transactions, setTransactions,
                transfers, setTransfers,
                purchase, setPurchase,
                savings, setSavings,
                isStash, setIsStash,
                isAllowed, setIsAllowed,
                selectedPortfolio, setSelectedPortfolio,
                investmentUnit, setInvestmentUnit,
                investmentPlanAmount, setInvestingPlanAmount,
                funds, setFunds,
                referralSlug , setReferralSlug,
                activeInvestments, setActiveInvestments,
                activeInvestmentBalance, setActiveInvestmentBalance
            }}>
            {children}
        </AccountContext.Provider>
    )
}
export default AccountProvider
