import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useContext, useState } from "react";
import Constants from "../../constants/Constants";
import { AccountContext } from "../../context/AccountContext";

let InvestmentUnitInputComponent = ({ unitPrice = 1 }) => {
    let { investmentPlanAmount, setInvestingPlanAmount } = useContext(AccountContext);
    let { investmentUnit, setInvestmentUnit } = useContext(AccountContext);

    
    //let [investmentUnit, setUnitValue] = useState(1);
    let handleIncrease = () => {

        //setUnitValue(investmentUnit + 1);
        setInvestmentUnit(investmentUnit + 1);
    }
    let handleDecrease = () => {
        if (investmentUnit - 1 >= 1) {
            // setUnitValue(investmentUnit - 1);
            setInvestmentUnit(investmentUnit - 1);
        }
        else {
            //    setUnitValue(1);
            setInvestmentUnit(1);
        }
    }
    let handleInput = (e) => {
        // setUnitValue(e.target.value);
        if (e.target.value > 1) {
            // setUnitValue(e.target.value);
            setInvestmentUnit(e.target.value);
        }
        else {
            //setUnitValue(1);
            setInvestmentUnit(1);
        }
    }
    return <>
        <div className="flex-col justify-center">
            <div className="flex border-2 mb-4 border-rouzo-base-color rounded-xl items-center  justify-between p-[22px]">
                {/*Negative button for reducing value */}
                <div onClick={handleDecrease} className="border items-center hover:cursor-pointer  py-2 px-2 rounded-[4px] bg-rouzo-base-color-op">
                    <FontAwesomeIcon className="text-base text-rouzo-base-color" icon={faMinus}></FontAwesomeIcon>
                </div>
                <div className="mx-4 flex-grow w-full px-6">
                    <input type="number" min="1" onChange={(e) => handleInput(e)} className="mulish-font text-rouzo-base-color w-full text-center text-[36px]" value={investmentUnit}></input>
                </div>
                <div onClick={handleIncrease} className="border hover:cursor-pointer py-2 px-2 rounded-[4px] bg-rouzo-base-color-op">
                    <FontAwesomeIcon className="text-base text-rouzo-base-color" icon={faPlus}></FontAwesomeIcon>
                </div>
            </div>
            <div className="flex-col justify-center text-center items-center">
                <p className="mulish-font font-bold text-xl text-rouzo-text-color-2">{investmentUnit} unit{investmentUnit > 1 ? "s" : ""} = {Constants.nairaString} {investmentUnit * unitPrice}{setInvestingPlanAmount(investmentUnit * unitPrice)}</p>
                <p className="text-sm text-rouzo-text-color">@{Constants.nairaString}{unitPrice} per unit</p>
            </div>
        </div>
    </>
}


export default InvestmentUnitInputComponent;