import firstImg from "../assets/img/home/4c52f8a7-2960-41d2-9404-99af9c60f9dd 1.svg";
import secondImg from "../assets/img/home/e2cb789c-c32e-4992-b2fd-c1a1ea1884ab 1.svg";
import thirdImg from "../assets/img/home/4e30fb2d-5718-4739-809d-5e10e6e7336a 1.svg";
import fourthImg from "../assets/img/home/IMG_9384 1.svg";
import SolarImg from "../assets/img/solarbw.jpg";
import WhatNextImg from "../assets/img/home/blackdigital.jpg"

let AboutUs2 = () => {
    return <>
        <div class="aboutus-bg font-lota bg-gray-100 text-black">
            <p className="font-bold text-[50px] text-right">Our Story</p>
            <br>
            </br>
            <div className="flex flex-col gap-10">
                <div class="flex flex-wrap ">
                    <div class="relative flex-grow max-w-full flex-1 px-4  flex justify-center">
                        <img src={firstImg}></img>
                    </div>
                    <div class="relative flex-grow max-w-full flex-1 px-4 self-center flex gap-2 items-start flex-row">
                        <div className="text-[50px] font-bold">
                            2019
                        </div>
                        <div>
                            <div class="flex flex-wrap  flex">
                                
                                <p class="font-bold text-xl relative flex-grow max-w-full flex-1 font-unbounded self-start">How the Journey Started</p>


                            </div>
                            <div>
                                <p class="small-text font-unbounded">When we first launched in 2019, Our initial approach to
                                    providing
                                    financial
                                    services involved curating vetted small
                                    businesses and pairing them with partnering
                                    financial institutions to offer working capital
                                    loans.
                                </p>
                            </div>
                            <div>
                                <p class="small-text font-unbounded">
                                    Within three months of its release,
                                    our original matching tool had already aided
                                    17 financial institutions in improving the
                                    lending experience for more than 500 clients.</p>
                            </div>
                        </div>

                    </div>
                </div>
                <br></br>
                <br></br>

                <div class="flex flex-wrap ">
                    <div class="relative flex-grow max-w-full flex-1 px-4 text-right flex gap-2 items-start flex-row">
                       
                        <div>
                            <div class="flex flex-wrap  text-align-right flex">
                               
                                <p class="relative text-xl font-bold flex-grow max-w-full flex-1  font-unbounded self-start">Moving forward</p>
                            </div>
                            <div>
                                <p class="small-text text-align-right font-unbounded">When COVID-19 Came, we needed to act fast. Our customers were at the whim of Banking partners who
                                    didn’t want to lend because economic activities were
                                    slowed down as a result of the pandemic. To achieve our
                                    mission, a quicker, more direct strategy was required.
                                    <br></br>
                                    Working on direct consumer and business-facing
                                    products was the first thing we did after utilizing our
                                    industry expertise, technical breadth, and customer-first
                                    philosophy. We created the peer-to-peer platform
                                    ROUZO, that enables savvy individuals and corporate
                                    organizations invest in portfolios that are used to finance
                                    small enterprises. In less than a year, loan transactions
                                    grew to over $1,000,000.
                                </p>
                            </div>
                        </div>
                        <div className="text-[50px] font-bold">
                            2020
                        </div>
                    </div>
                    <div class="relative flex-grow max-w-full flex-1 px-4 self-center flex justify-center">
                        <img class="mx-auto" src={secondImg}></img>
                    </div>

                </div>
                <br></br>
                <br></br>
                <div class="flex flex-wrap ">
                    <div class="relative flex-grow max-w-full flex-1 px-4 flex justify-center ">
                        <img src={fourthImg}></img>
                    </div>
                    <div class="relative flex-grow max-w-full flex-1 px-4 self-center flex gap-2 items-start flex-row">
                        <div className="text-[50px] font-bold">
                            2021
                        </div>
                        <div>
                            <div class="flex flex-wrap  flex">
                                {/*<hr style={{ padding: "2px" }} class="relative flex-grow max-w-full flex-1 px-4 theme-bg-purple-light self-end"></hr>*/}
                                <p class="relative flex-grow text-xl font-bold max-w-full flex-1 font-unbounded self-start">New Discoveries</p>


                            </div>
                            <div>
                                <p class="small-text font-unbounded"> After months of fine tuning our innovative technology for small
                                    businesses. We realized our target market was missing out
                                    on the benefits of this technology. Despite all of the
                                    rigorous test and trials, we needed to find a way to reach those customers who could not access technology due to
                                    lack of resources or technical know-how.
                                </p>
                            </div>
                            <div>
                                <p class="small-text font-unbounded">
                                    We built Fara, an all-inclusive financial platform that leverages our app and a wide network of partners to offer
                                    financial services through payments, loans, savings, health
                                    insurance and pensions to the unbanked in low income
                                    communities.  Our approach is embedded in understanding our
                                    clients CULTURE, providing them the right financial EDUCATION
                                    and utilizing TECHNOLOGY to help them grow and increase wealth.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <br></br>

                <div class="flex flex-wrap ">
                    <div class="relative flex-grow max-w-full flex-1 px-4 text-right self-center flex gap-2 items-start flex-row">
                       
                        <div>
                            <div class="flex flex-row flex">
                                
                                <p class="relative text-xl font-bold flex-grow max-w-full flex-1  font-unbounded self-start">Go Green, Or be left behind..
                                </p>
                            </div>
                            <div>
                                <p class="small-text text-align-right font-unbounded">The World is changing. So much of our impact on the Earth is determined by how we spend and make our money. Consumers, regulations, and resource scarcity are pushing businesses to go green..
                                    In 2023, our commitment to sustainability led us to streamline financing for businesses with good eco-practices. We rebranded our trade name as Rouzo believing Africa’s pathway to a sustainable future is in its own people.

                                </p>
                            </div>
                        </div>
                        <div className="text-[50px] font-bold">
                            2023
                        </div>
                    </div>
                    <div class="relative flex-grow max-w-full flex-1 px-4 flex justify-center">
                        <img className="rounded-full " src={SolarImg}></img>
                    </div>

                </div>
                <br></br>
                <br></br>

                <div class="flex flex-wrap ">
                    <div class="relative flex-grow max-w-full flex-1 px-4 flex justify-center">
                        <img className="rounded-full h-[500px] w-[400px]" src={WhatNextImg}></img>
                    </div>

                    <div class="relative flex-grow max-w-full flex-1 px-4 text-right self-center flex gap-2 items-start flex-row">
                        <div className="text-[50px] font-bold">
                            What's Next?
                        </div>
                        <div>
                            <div class="flex flex-row flex">
                               
                                <p class="relative text-xl font-bold flex-grow max-w-full flex-1  font-unbounded self-start">What should you expect?</p>
                            </div>
                            <div>
                                <p class="small-text text-align-right font-unbounded">For us, it is still DAY 1.
                                    One thing we are sure of is to continue using technology
                                    to democratize financial services that will ensure
                                    entrepreneurs have access to an abundant life.
                                    While this seems interesting, we are just starting. The goal
                                    remains to empower over 1,000,000 SMEs in 5,000
                                    communities with access to financing and tools to grow sustainably.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </>
}


export default AboutUs2