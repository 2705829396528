import TopComponentGeneral from "../components/landingpage/TopComponentGeneral";
import DroneVideo from "../assets/drone-green.mp4";
import CTAButtonComponent from "../components/CTAButtonComponent";
import ButtonComponent from "../components/ButtonComponent";
import SideBySideComponent from "../components/landingpage/SideBySideComponent";
import ImageComponent from "../components/landingpage/ImageComponent";
import TreesImage from "../assets/img/trees.png";
import ContributeImage from "../assets/img/smiling-girl-putting-coin-piggybank 1.png";
import SplitImage from "../assets/img/[GetPaidStock 16.png"
import TextualComponent from "../components/landingpage/TextualComponent";
import Constants from "../constants/Constants";
import CircleMask from "../assets/img/circle-mask.svg";
import DashboardImage from "../assets/img/Dashboard.svg";
import FooterComponent from "../components/landingpage/FooterComponent";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AboutUs from "./AboutUs";
import onionSeller from "../assets/img/onion-seller.jpg";
import TopMarket from "../assets/img/top-market.jpg"
import Careers from "./Careers";
import Impact from "./Impact";
let LandingPage = () => {
    let [financeType, setFinanceType] = useState("Personal");
    let [started, setStarted] = useState(false);

    let financeTypeList = ["Personal", "Social", "Inventory", "Credit", "Business"];
    let [increment, setIncrement] = useState(0);




    useEffect(() => {

        let handleFlip = setInterval(() => {
            if (increment > 4) {
                setIncrement(0);
            }
            console.log("This is i : ", increment);
            if (increment > 4) {
                increment = 0;
                setIncrement(0)
                console.log(increment > 4);
            }
            setFinanceType(financeTypeList[increment]);
            setIncrement(increment + 1);
        }, 2000)


        return () => clearInterval(handleFlip);
    }, [increment])


    return <>
        <div className="flex w-full font-lota flex-col">
            <TopComponentGeneral></TopComponentGeneral>
            <div className="w-full flex-col" >
                <div className="">
                    <div style={{ background: `url('${TopMarket}')`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} className="p-6 py-[60px] pt-[150px] md:py-60mulish-font flex  flex-row md:px-[100px]">
                        <div className=" text-7xl flex-col bg-gray-100 p-[50px] rounded-xl">
                            <div className="font-extrabold">
                                <p className="text-rouzo-base-color animate-pulse">{financeType} <span className="text-black">Finance</span></p>
                                <p className="text-black">that protects</p>
                                <p className="text-black">the <span className="text-rouzo-green-light"> Planet</span></p>
                            </div>
                            <div className="pt-2 mb-12">
                                <p className=" text-xl text-black">Fund sustainable businesses, Earn sustainable returns.</p>
                            </div>
                            <div className="flex">
                                <div className="hover:cursor-pointer">
                                    <Link to={"https://app.rouzo.co/login"}>
                                        <ButtonComponent text={"Join Rouzo"}>Join us</ButtonComponent>
                                    </Link>

                                </div>

                            </div>
                        </div>
                        <div>

                        </div>
                    </div>

                </div>
                <div className="">
                    <AboutUs></AboutUs>
                </div>
                <div>
                    <Careers></Careers>
                </div>
                <div>
                    <Impact></Impact>
                </div>
                <div className="p-8 md:px-[100px] md:py-[87px]">
                    <div className=" mulish-font bg-rouzo-base-color rounded-[54px]">
                        <div style={{ background: `url(${CircleMask})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover" }} className="md:p-20 p-8 flex-col md:flex-row md:text-left text-center justify-center md:flex rounded-[54px] md:justify-between ">
                            <div className="flex text-white text-center md:text-left flex-col gap-4">
                                <p className="font-bold text-[32px]">JOIN A COMMUNITY OF PEOPLE BUILDING A GREENER FUTURE.</p>
                                <p className="text-base font-bold mulish-font">Subscribe to the lastest news and product updates from Rouzo</p>
                                <div className="flex">

                                </div>
                            </div>
                            <div >
                                <img src={DashboardImage}></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <FooterComponent></FooterComponent>
                </div>
            </div>
        </div>
    </>
}


export default LandingPage;