import InvestmentComponent from "./InvestmentComponent";


let InvestmentList = ({investments}) => {
    return <>
        {investments ? console.log(investments) : ""}
        {investments ? investments.sort((a,b) => a.id + b.id).map(plan => {
            return <div className="mb-3">
                <InvestmentComponent {...plan}></InvestmentComponent>
            </div>
        }) :
            <InvestmentComponent example={true} name={"Travel to Manitoba"} amount={20000} status={"ongoing"} ></InvestmentComponent>
        }
    </>
}


export default InvestmentList;