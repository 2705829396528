
let Careers = () => {
    return <>
        <div className="flex flex-col pt-20 py-20 px-10 md:px-[100px]">

            <div className="text-right font-light flex flex-col gap-2">
                <p>____________CAREERS</p>
                <p className="font-bold text-right">OUR PEOPLE & CULTURE</p>
            </div>
            <div className="flex lota-font font-lota gap-3 flex-wrap font-thin">

                <p>
                    At our company, we understand that success is more than just technology. It's about the people who bring it to life. That's why we foster a culture built on five core values:<br></br> <span className="font-bold" >Passion, Integrity, Impact, Creativity, and Collaboration.</span>

                </p>
                
                <p>
                    <span className="font-bold">Passion is our spark: </span>It fuels our drive to innovate, our perseverance in the face of challenges, and our relentless pursuit of excellence. We believe passion transforms challenges into opportunities, and inspires us to exceed expectations in everything we do.
                </p>
                <p>
                    <span className="font-bold">Integrity is our foundation:  </span>We hold ourselves to the highest ethical standards, conducting business with honesty, transparency, and accountability.  Our commitment to integrity means doing the right thing, always.
                </p>
                <p>
                    <span className="font-bold">Impact is how we scale: </span>We believe success goes beyond financial metrics. We strive to make a positive difference in the world. Whether it's empowering communities, protecting the environment, driving social change, or advancing knowledge, we use our resources and influence for good.
                </p>
                <p>
                    <span className="font-bold">Creativity is our compass. </span>We embrace diverse perspectives and encourage bold, innovative thinking. We celebrate experimentation, learn from failures, and never settle for the status quo.
                </p>
                <p><span className="font-bold">Collaboration is our strength. </span>We believe that great things are rarely achieved alone. Through collaboration, we amplify our strengths, bridge gaps in knowledge, and achieve extraordinary outcomes together
                </p>
            </div>
            <div>

            </div>
        </div>
    </>
}

export default Careers;