import BalanceCardComponent from "../../components/BalanceCardComponent"
import CTAButtonComponent from "../../components/CTAButtonComponent"
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import TransactionHistoryComponent from "../../components/TransactionHistoryComponent";
import NewSavingPlan from "../../components/modals/NewSavingPlan";
import { useContext, useState } from "react";
import SavingPlansList from "../../components/savings/SavingPlansList";
import { motion } from "framer-motion";
import { AccountContext } from "../../context/AccountContext";
import WithdrawalComponent from "../../components/modals/WithdrawalComponent";
import NotificationComponent from "../../components/NotificationComponent";
import FormatMoneyComponent from "../../components/misc/FormatMoneyComponent";

let SavingDashboard = () => {
    let [isNewSavingPlanModalActive, setNewSavingPlanModalActive] = useState(false);
    let [isWithdrawalModalActive, setWithdrawalModalActive] = useState(false);
    let { stashBalance, availableAmount } = useContext(AccountContext);
    let { savings } = useContext(AccountContext);
    return <>


        <div className={`pt-9 pl-10 pr-10 mulish-font flex-grow ${isNewSavingPlanModalActive ? "blur-sm" : ""}`}>
            <motion.div
                className='flex-grow'
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
            >

                <div className='flex flex-row justify-between mb-10 w-full'>
                    <div className=''>
                        <p className='mulish-font font-medium text-[32px] text-rouzo-text-color-2 mb-3'>Save</p>
                        <div>
                            <p className='text-base text-rouzo-text-color font-medium'>Start small and watch your savings grow over time</p>
                        </div>
                    </div>
                    <div>
                        <NotificationComponent></NotificationComponent>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 mb-10 gap-4">
                    <BalanceCardComponent balanceText={"Stash Balance"}
                        amount={availableAmount ? <FormatMoneyComponent amount={availableAmount}></FormatMoneyComponent> : 0}
                        earningsValue={0.00}>
                    </BalanceCardComponent>
                    <div className="flex items-center space-x-6 justify-center">
                        <CTAButtonComponent onClick={() => setWithdrawalModalActive(true)} icon={faArrowUp} text="Withdraw"> </CTAButtonComponent>
                        <CTAButtonComponent onClick={() => setNewSavingPlanModalActive(true)} icon={faArrowDown} text="Save to Invest"> </CTAButtonComponent>
                    </div>
                </div>

                <div className="grid md:grid-cols-2 md:space-x-6">
                    <div>
                        <p className="text-rouzo-text-color-2 text-sm font-semibold text-xl mb-10">My Saving Plans</p>
                        <SavingPlansList savingplans={savings.data ? savings.data : null}></SavingPlansList>
                    </div>
                    <div>
                        <TransactionHistoryComponent></TransactionHistoryComponent>
                    </div>

                </div>

            </motion.div>

        </div>
        {isWithdrawalModalActive ? <WithdrawalComponent setIsModalActive={setWithdrawalModalActive} ></WithdrawalComponent> : ""}
        {isNewSavingPlanModalActive ? <NewSavingPlan setNewSavingPlanModalActive={setNewSavingPlanModalActive}></NewSavingPlan> : ""}
    </>
}


export default SavingDashboard