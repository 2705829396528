import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccountContext } from "../../context/AccountContext";
import NavBackComponent from "../../components/misc/NavBackComponent";
import { toast } from "react-toastify";
import BalanceCardComponent from "../../components/BalanceCardComponent";
import SavingPlanProgressComponent from "../../components/savings/SavingPlanProgressComponent";
import { setBlockTracking } from "vue";
import useAccounts from "../../hooks/useAccount";



let SavingPlanSummary = () => {
    let navigate = useNavigate();

    let { savingPlanId } = useParams();
    let { savings } = useContext(AccountContext);
    let [savingPlan, setSavingPlan] = useState(null);
    let {fetchProfile} =  useAccounts();

    useEffect(() => {

        if (savings.data) {
            let _savingPlan = savings.data.filter(o => o.id == savingPlanId)[0];
            console.log(_savingPlan);
            if (_savingPlan == null) {
                toast.error("Saving plan does not exist");
                navigate("/dashboard/save");
            }
            else {
                setSavingPlan(_savingPlan);
            }
        }
        else{
            fetchProfile();
        }
    }, [])
    return <>
        <div className="pt-9 pl-10 pr-10 mulish-font flex-grow">
            <div className="mb-4">
                <NavBackComponent title={`${savingPlan ? savingPlan.name : ""} Summary`}></NavBackComponent>
            </div>
            <div className="md:grid md:grid-cols-2  mb-10 gap-4">
                <BalanceCardComponent balanceText={"Saving amount"} amount={savingPlan ? `${savingPlan.amount} ${savingPlan.interval}` : "0 daily"}>

                </BalanceCardComponent>
                <div className="md:mt-0 mt-4 md:flex  flex-col items-center space-x-6 justify-center">
                    <SavingPlanProgressComponent current={savingPlan ? savingPlan.monthsPaid : 0} expected={savingPlan ? savingPlan.months : 0}
                        interval={savingPlan ? `${savingPlan.interval == "daily" ? "days" : savingPlan.interval == "weekly" ? "weeks" : "months"}` : "daily"}></SavingPlanProgressComponent>
                </div>
            </div>
        </div>
    </>
}

export default SavingPlanSummary;