import { useContext, useEffect, useRef, useState } from "react";
import InputComponent from "../InputComponent";
import CTAButtonComponent from "../CTAButtonComponent";
import { AnimatePresence, motion } from "framer-motion";
import ModalRootComponent from "./ModalRootComponent";
import Constants from "../../constants/Constants";
import { faCreditCard, faBuildingColumns, faMoneyBillTransfer } from "@fortawesome/free-solid-svg-icons";
import SelectComponent from "../SelectComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import InvestingsService from "../../api/services/InvestingService";
import { AccountContext } from "../../context/AccountContext";
import InvestmentUnitInputComponent from "../investments/InvestmentUnitInputComponent";
import useAccounts from '../../hooks/useAccount';

let NewInvestment = ({ focus, setIsNewInvestmentModalActive, portfolio }) => {
    let [currentPage, setCurrentPage] = useState(1);
    let { investor, } = useContext(AccountContext);
    let { fetchProfile } = useAccounts();
    let [investmentPlanName, setInvestingPlanName] = useState("");

    let [investmentPlanLength, setInvestingPlanLength] = useState(null);
    let [investmentPlanFrequency, setInvestingPlanFrequency] = useState("daily");
    {/*From the investment unit component*/ }
    let { investmentUnit } = useContext(AccountContext);
    let { investmentPlanAmount, setInvestingPlanAmount } = useContext(AccountContext);
    let inputReference = useRef(null);

    let [investmentInterest, setInvestmentInterest] = useState(null);


    useEffect(() => {
        // console.log("InvestmentUnit: ", investmentUnit);
        if(!investmentInterest){
            setInvestmentInterest(portfolio.interestRates[0].interest);
        }
        if(!investmentPlanLength){
            setInvestingPlanLength(portfolio.interestRates[0].value)
        }
    }, [])
    let page = {
        back: () => {
            if (currentPage == 1) {
                console.log(currentPage);
                console.log("we should gp back");
                console.log(setIsNewInvestmentModalActive)
                setIsNewInvestmentModalActive(false);

            }
            if (currentPage > 1) {
                setCurrentPage(currentPage => currentPage - 1);
            }

        },
        next: () => {
            if (currentPage < 5) {
                setCurrentPage(currentPage => currentPage + 1);
            }
        }
    }
    let handleProceedPayment = async () => {
        if (!investmentPlanAmount) {
            setCurrentPage(2);
            return toast.error("You missed your investment plan amount")
        }
        if (!investmentPlanFrequency) {
            setCurrentPage(4);
            return toast.error("You missed to set how often you want to invest")
        }
        if (!investmentPlanLength) {
            setCurrentPage(3);
            return toast.error("You missed setting up how long you want to invest for");
        }
        if (investmentPlanAmount && investmentPlanFrequency && investmentPlanLength) {
            page.next();

        }
    }

    let pay = async (investmentPaymentMethod) => {
        console.log({
            portfolioName: portfolio.name,
            portfolioId: portfolio.id,
            unitsBought: investmentUnit,
            amount: investmentPlanAmount,
            months: investmentPlanLength,
            percentage: investmentInterest,
            paymentMethod: investmentPaymentMethod
        })
        let newInvesting = await InvestingsService.create({
            portfolioName: portfolio.name,
            portfolioId: portfolio.db_id,
            unitsBought: investmentUnit,
            amount: investmentPlanAmount,
            months: investmentPlanLength,
            percentage: investmentInterest,
            paymentMethod: investmentPaymentMethod
        })
        console.log("New investment::  ", newInvesting);
        if (newInvesting) {
            if (newInvesting.type == "success") {

                if (newInvesting.data) {
                    let { url } = newInvesting.data;
                    if (url) {
                        toast.success("Redirecting to payment gateway shortly to initiate your investment process.");
                        console.log(url);
                        setTimeout(() => {
                            window.location = url;
                        }, 3000);
                    }
                    setIsNewInvestmentModalActive(false);
                }

                else {
                    toast.success(newInvesting.message);
                    setIsNewInvestmentModalActive(false);
                    fetchProfile();

                }

            }
            else if (newInvesting.type == "error") {
                toast.error(newInvesting.message)
            }
        }
    }
    let handleInvestingPlanLength = (e) => {

        setInvestingPlanLength(e.target.value);
        console.log("InvestingPlanLength: ", investmentPlanLength);
        console.log(portfolio.interestRates.filter(o => o.value == e.target.value)[0].interest);
        setInvestmentInterest(portfolio.interestRates.filter(o => o.value == e.target.value)[0].interest);
    }
    let handlePageSwitch = () => {
        console.log("page switch");
        console.log(currentPage);
        switch (currentPage) {
            case 1:
                return <div>
                    <p className="text-rouzo-text-color-2 mulish-font font-bold text-[32px] mb-6">New {portfolio.name ? portfolio.name.replace("Portfolio", "") : ""} Investment</p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="mb-[100px]">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How many units would you like to invest in?</p>
                            <InvestmentUnitInputComponent unitPrice={portfolio.pricePerUnit}></InvestmentUnitInputComponent>
                        </div>
                    </motion.div>


                </div>
            case 2:
                return <div>
                    <p className="text-rouzo-text-color-2 font-bold text-[32px] mb-6">New {portfolio.name ? portfolio.name.replace("Portfolio", "") : ""} Investment</p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="mb-4 md:mb-[[100px]] mulish-font">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How many units you are investing in  </p>
                            <div className="mulish-font ">
                                <input disabled={true} className="py-[21px] w-full px-[25px] bg-rouzo-blue-light rounded-xl" value={investmentUnit}></input>
                            </div>
                        </div>
                        <div className="mb-[100px]">
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">How long do you want to invest for?</p>
                            <SelectComponent onChange={(e) => handleInvestingPlanLength(e)} data={portfolio.interestRates ? portfolio.interestRates : Constants.SAVINGS.PLANS_LENGTH} ></SelectComponent>
                        </div>

                    </motion.div>

                </div>
            case 3:
                return <div>
                    <p className="text-rouzo-text-color-2 mulish-font font-bold text-base mb-6">Investment Summary</p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                        className="mb-4"
                    >
                        <p className="font-bold text-rouzo-text-color-2 mulish-font text-2xl mb-2">{portfolio.name ? portfolio.name : "Portfolio"}</p>
                        <hr className="mb-2"></hr>
                        <div className="flex-col gap-4 mb-2 mulish-font" >
                            <div className="flex justify-between py-1">
                                <p className="text-sm text-rouzo-text-color font-semibold">Amount:</p>
                                <p className="text-rouzo-text-color-2 font-bold text-base">₦ {investmentPlanAmount}</p>
                            </div>
                            <div className="flex justify-between py-1">
                                <p className="text-sm text-rouzo-text-color font-semibold">Number of unit:</p>
                                <p className="text-rouzo-text-color-2 font-bold text-base"> {investmentUnit} unit{investmentUnit > 1 ? "s" : ""}</p>
                            </div>
                            <div className="flex justify-between py-1">
                                <p className="text-sm text-rouzo-text-color font-semibold">Duration:</p>
                                <p className="text-rouzo-text-color-2 font-bold text-base"> {investmentPlanLength} Months</p>
                            </div>
                            <div className="flex justify-between py-1">
                                <p className="text-sm text-rouzo-text-color font-semibold">Expected Interest:</p>
                                <p className="text-rouzo-text-color-2 font-bold text-base"> {investmentInterest} %</p>
                            </div>
                        </div>
                        <hr className="mb-2"></hr>
                        <div className="flex justify-between py-1">
                            <p className="text-sm text-rouzo-text-color font-semibold">ROI:</p>
                            <p className="text-rouzo-text-color-2 font-bold text-base"> {Constants.nairaString} {((investmentInterest / 100) * investmentPlanAmount) + investmentPlanAmount}</p>
                        </div>

                    </motion.div>

                </div>;
            case 4:
                return <div>
                    <p className="text-rouzo-text-color-2 mulish-font font-bold text-[32px] mb-6">Pay ₦{investmentPlanAmount} using : </p>
                    <motion.div
                        initial={{ opacity: 0, left: "-12rem" }}
                        animate={{ opacity: 1, left: "12rem" }}
                        transition={{ duration: 4 }}
                    >
                        <div className="flex flex-col space-y-6">
                            <div onClick={() => pay("bank")} className="flex space-x-[12px] bg-rouzo-blue-light p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-pointer hover:border-rouzo-base-color">
                                <FontAwesomeIcon icon={faCreditCard} className="text-rouzo-text-color-2"></FontAwesomeIcon>
                                <p className="text-sm font-semibold">Card</p>
                            </div>
                            <div onClick={() => pay("bank")} className="flex space-x-[12px] bg-rouzo-blue-light p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-pointer hover:border-rouzo-base-color">
                                <FontAwesomeIcon icon={faMoneyBillTransfer} className="text-rouzo-text-color-2"></FontAwesomeIcon>
                                <p className="text-sm font-semibold">Transfer</p>
                            </div>
                            <div onClick={() => pay("bank")} className="flex space-x-[12px] bg-rouzo-blue-light p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-pointer hover:border-rouzo-base-color">
                                <FontAwesomeIcon icon={faBuildingColumns} className="text-rouzo-text-color-2"></FontAwesomeIcon>
                                <p className="text-sm font-semibold">Bank</p>
                            </div>
                            <div onClick={() => pay("stash")} className="flex space-x-[12px] bg-rouzo-blue-light p-6 mulish-font rounded-lg text-rouzo-text-color-2 hover:border hover:cursor-pointer hover:border-rouzo-base-color">
                                <FontAwesomeIcon icon={faBuildingColumns} className="text-rouzo-text-color-2"></FontAwesomeIcon>
                                <p className="text-sm font-semibold">Rouzo Stash</p>
                            </div>
                        </div>

                    </motion.div>
                </div>
        }
    }
    return <>
        <ModalRootComponent
            children={
                <div ref={inputReference} className="z20 mulish-font rounded-2xl self-center p-6 pt-9 bg-white">

                    <div onClick={page.back} className="hover:cursor-pointer text-rouzo-text-color-2 font-medium text-xl">
                        <p>{'<'} back</p>
                    </div>
                    <div>
                        {handlePageSwitch()}
                    </div>
                    <div className="text-right flex justify-end ">
                        <div >
                            {
                                currentPage < 3 ?
                                    <CTAButtonComponent onClick={page.next} text={(currentPage < 3) ? "Continue" : "Proceed to Payment"}></CTAButtonComponent>
                                    : ""
                            }
                            {
                                currentPage == 3 ?
                                    <CTAButtonComponent onClick={handleProceedPayment} text={"Proceed to Payment"}></CTAButtonComponent>
                                    : ""
                            }



                        </div>

                    </div>
                </div>
            }
        ></ModalRootComponent>
    </>

}


export default NewInvestment;