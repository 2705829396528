import React, { useContext } from "react";
import { useState } from "react";
import LogoComponent from "../../components/LogoComponent";
import ButtonComponent from "../../components/ButtonComponent";
import InvestorCategoryList from "../../components/InvestorCategoryList";
import InputComponent2 from "../../components/InputComponent2";
import InputComponent from "../../components/InputComponent";
import Constants from "../../constants/Constants";
import { OnboardContext } from "../../context/OnboardContext";
import useOnboard from "../../hooks/useOnboard";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import SelectComponent2 from "../../components/SelectComponent2";

let Onboard = () => {
    let [stage, setStage] = useState(0);
    const { onboard } = useOnboard();
    let { firstName, lastName, setFirstName, setLastName } = useContext(OnboardContext);
    let { password, setPassword } = useContext(OnboardContext);
    let [confirmPassword, setConfirmPassword] = useState("");
    let { address, setAddress } = useContext(OnboardContext);
    let { investorType, setInvestorType } = useContext(OnboardContext);
    let { mobileNumber, setMobileNumber } = useContext(OnboardContext);
    let { emailAddress, setEmailAddress } = useContext(OnboardContext);
    let [isLoading, setIsLoading] = useState(false);
    let createAccount = async (e) => {
        //e.target.disabled
        if(isLoading == true) {
            return ;
        }
        setIsLoading(true);
        if (!firstName || !lastName || !address || !emailAddress) {
            toast.error("You are missing a required detail here");
            setStage(1);
            return;
        }
        else if (!password || !confirmPassword) {
            toast.error("Check the password fields");
            setStage(2);
            return;

        }
        else if(password && password.length < 8){
            toast.error("Check the password fields. Your password characters should be greater than 8");
            setStage(2);
        }
        console.log(password, " ___ ", confirmPassword);
        console.log(password == confirmPassword);
        console.log(password != confirmPassword)

        if (password != confirmPassword) {
            toast.error("Passwords did not match. Try again");
            setStage(2);
            return;
        }

        let data = {
            f_name: firstName,
            l_name: lastName,
            address: address,
            password: password,
            terms: "on",
            type: 'investor',
            lenderCategoryId: investorType,
            phone: mobileNumber,
            email: emailAddress,
            password_confirmation: confirmPassword
        }
        console.log(data);
        await onboard(data);
        setIsLoading(false);
    }

    let nextStage = () => {
        console.log(stage);
        if (stage < 2) setStage(stage => stage + 1);
    }
    let handleStages = () => {
        switch (stage) {
            case 0:
                return <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8 }} className="flex flex-col gap-y-6 ">
                    <p className="font-medium text-xl mb-6 justify-center text-center ">What type of investor are you?</p>
                    <div>
                        <InvestorCategoryList investorTypes={Constants.INVESTOR_TYPES}></InvestorCategoryList>
                    </div>
                    <div className="flex justify-center">
                        <ButtonComponent className={"w-1/2"} extra={{ onClick: nextStage }} text="Continue"></ButtonComponent>
                    </div>
                </motion.div>
            case 1:
                return <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8 }}
                    className="p-10 flex flex-col space-y-6 drop-shadow-md border bg-white">
                    <InputComponent2 onChange={(e) => setFirstName(e.target.value)} value={firstName} type={"text"} placeholder={"First name"}></InputComponent2>
                    <InputComponent2 onChange={(e) => setLastName(e.target.value)} value={lastName} type={"text"} placeholder={"Last name"}></InputComponent2>
                    <div className="flex">
                        <div>
                            <SelectComponent2 disabled={true} data={[{
                                text: "+234",
                                value: "+234"
                            }]}></SelectComponent2>
                        </div>

                        <div className="flex-grow grow">
                            <InputComponent2 onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber} type={"text"} placeholder={"08123456789"}></InputComponent2>
                        </div>

                    </div>

                    <InputComponent2 onChange={(e) => setEmailAddress(e.target.value)} value={emailAddress} type={"text"} placeholder={"Email"}></InputComponent2>
                    <InputComponent2 onChange={(e) => setAddress(e.target.value)} value={address} type={"text"} placeholder={"Address"}></InputComponent2>
                    <ButtonComponent extra={{ onClick: nextStage }} text="Continue"></ButtonComponent>
                </motion.div>
            case 2:
                return <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.8 }}
                    className="p-10 flex flex-col  drop-shadow-md border bg-white">
                    <div className="mb-[8px]">
                        <InputComponent2 onChange={(e) => setPassword(e.target.value)} type={"password"} value={password} placeholder={"Create password"}></InputComponent2>
                    </div>
                    <p className="mb-6">Enter at least 8 characters. Don’t use common words, names, or sequential or repeated characters.</p>
                    <InputComponent2 onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} type={"password"} placeholder={"Retype password"}></InputComponent2>
                    <div className="flex justify-center my-6 space-x-6">
                        <InputComponent type="checkbox"></InputComponent>
                        <p>I agree to the Terms & Conditions</p>
                    </div>
                    <div className="flex justify-center">
                        <ButtonComponent isLoading={isLoading} className={"w-1/2"}  extra={{ onClick: createAccount }} text="Create Account"></ButtonComponent>
                    </div>
                </motion.div>
        }
    }
    return <>
        <div className="p-6 md:p-10 mulish-font">
            <div className="md:flex mb-[64px] md:visible invisible hidden">
                <div className="fixed">
                    <LogoComponent></LogoComponent>
                </div>
                <div className="grow text-center">
                    <p className="font-semibold text-[32px] text-rouzo-text-color-2">Create an Account</p>
                    <p className="font-regular text-base">Join thousands of investors earning by Investing into businesses.</p>
                </div>
            </div>
            <div className="md:invisible md:hidden flex justify-center  ">
                <div className="">
                    <LogoComponent></LogoComponent>
                </div>
            </div>
            <div className="flex justify-center">
                {handleStages()}
            </div>

        </div>
    </>
}


export default Onboard;