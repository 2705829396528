import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


let ButtonComponent = ({isLoading,onClick, text, className,extra}) => {
    return <>
        <div {...extra} className={`p-[17px] flex flex-row gap-2 items-center bg-rouzo-base-color text-center hover:cursor-pointer rounded-lg ${className ? className : ""}`}>
            <p className="text-white font-bold text-xl">{text}</p>
            {isLoading ? 
                <FontAwesomeIcon className="text-white text-sm" icon={faSpinner} spin={true}></FontAwesomeIcon>
                 : ""
            }
        </div>
    </>
}


export default ButtonComponent;