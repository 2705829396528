import CTAButtonComponent from "../CTAButtonComponent";
import banner from "../../assets/img/NftBanner1.png";


let PortfolioSummaryComponent = ({
    riskLevel,
    portfolioSize,
    insurancePartner,
    pricePerUnit,
    name,
    portfolioDescription,
    interestRate,
    numberOfUnits,
    customBanner,
    onClick
}) => {
    return <>
        <div>
            <div className="flex-col space-y-10 mulish-font">
                <p className="font-bold text-[32px]"> About Portfolio</p>
                <img className="rounded-lg max-h-[285px]" src={customBanner ? customBanner : banner}></img>
                <div>
                    <div className="flex justify-between mb-[29px]">
                        <p className="font-bold text-[32px]">{name ? name : "Asset Finance Portfolio"}</p>
                        <div className="md:flex ">
                            <CTAButtonComponent onClick={onClick} text={"Invest now"}></CTAButtonComponent>
                        </div>
                    </div>
                    <p className="font-bold text-[18px]">Overview</p>
                    <div className="grid grid-cols-1 md:flex justify-between mb-6">
                        <div className="mb-4 md:mb-0 mulish-font flex-grow text-rouzo-text-color-2">
                            <p className="text-base font-regular">{portfolioDescription}</p>
                        </div>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                            <div className="rounded-lg py-[8px] px-[9px] flex-col flex space-y-3 bg-rouzo-grey">
                                <p className="font-medium text-base text-rouzo-text-color-2">Returns (per annum)</p>
                                <p className="font-bold text-base text-rouzo-base-color ">{interestRate}%</p>
                            </div>
                            <div className="rounded-lg py-[8px] px-[9px] flex-col flex space-y-3 bg-rouzo-grey">
                                <p className="font-medium text-base text-rouzo-text-color-2">Amount Per Units</p>
                                <p className="font-bold text-base text-rouzo-base-color">₦{pricePerUnit}</p>
                            </div>
                            <div className="rounded-lg py-[8px] px-[9px] flex-col flex space-y-3 bg-rouzo-grey">
                                <p className="font-medium text-base text-rouzo-text-color-2">Number Of Units</p>
                                <p className="font-bold text-base text-rouzo-base-color">{numberOfUnits}</p>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 md:flex items-center md:justify-between md:space-x-6 mb-[20px]">
                        <div className="mb-4 md:mb-0 mulish-font flex-grow text-rouzo-text-color-2">
                            <p className="text-base font-regular">Interest Options</p>
                            <div className="border p-[20px]">

                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-x-6 gap-y-6">
                            <div className="rounded-lg py-[8px] px-[9px] flex-col flex space-y-3 bg-rouzo-grey">
                                <p className="font-medium text-base text-rouzo-text-color-2">Risk Level</p>
                                <p className="font-bold text-base text-rouzo-base-color">{riskLevel}</p>
                            </div>
                            <div className="rounded-lg py-[8px] px-[9px] flex-col flex space-y-3 bg-rouzo-grey">
                                <p className="font-medium text-base text-rouzo-text-color-2">Portfolio Size</p>
                                <p className="font-bold text-base text-rouzo-base-color">₦{portfolioSize}</p>
                            </div>
                            <div className="rounded-lg py-[8px] px-[9px] flex-col flex space-y-3 bg-rouzo-grey md:text-right">
                                <p className="font-medium text-base text-rouzo-text-color-2">Insurance Partner</p>
                                <p className="font-bold text-base text-rouzo-base-color">{insurancePartner}</p>
                            </div>
                            <div className="rounded-lg py-[8px] px-[9px] flex-col flex space-y-3 bg-rouzo-grey md:text-right">
                                <p className="font-medium text-base text-rouzo-text-color-2">Management Fee</p>
                                <p className="font-bold text-base text-rouzo-base-color">0.5%</p>
                            </div>
                        </div>
                    </div>
                    <div className="mulish-font mb-4">
                        <p className="mulish-font font-bold text-sm text-rouzo-text-color-2">Terms of Use</p>
                        <p >Kindly read through the Investor's Terms of Use</p>
                    </div>
                    <div className="md:hidden justify-center ">
                        <div className=" text-center">
                            <CTAButtonComponent onClick={onClick} text={"Invest now"}></CTAButtonComponent>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>
}


export default PortfolioSummaryComponent;