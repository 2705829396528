import LogoComponent from "../components/LogoComponent";
import FooterComponent from "../components/landingpage/FooterComponent";


let SolarFi = () => {
    return <>
        <div className="">
            <LogoComponent></LogoComponent>
            <div className="p-8 flex justify-center">
                <iframe frameborder="0" onmousewheel="" width="100%" height="1600" src="https://forms.zohopublic.com/rouzo/form/SolarFinance/formperma/0wxY220iTag2yrzNsUdOeJRQwbdnaVUJLj0XDtQihYk" >

                </iframe>
            </div>

            <FooterComponent></FooterComponent>
        </div>
    </>
}

export default SolarFi;