
import Logo from '../assets/img/logo.svg';

let LogoComponent = () => {
    return <>
        <div className='items-center text-center'>
            <img className='mb-0 h-10' src={Logo}></img>
        </div>
    </>
}


export default LogoComponent