import { useEffect, useState } from "react";


let FormatMoneyComponent = ({ amount }) => {
    let [formattedMoney, setFormattedMoney] = useState(null);

    useEffect(() => {
        if(!formattedMoney){
            formatMoney();
        }
    }, [])

    let formatMoney = () => {
        // Convert the amount to a string.
        const amountString = amount.toString();

        // Split the string into two parts, at the decimal point.
        const [integerPart, decimalPart] = amountString.split('.');

        // Add commas to the integer part of the string.
        const integerPartWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        setFormattedMoney( `${integerPartWithCommas}${decimalPart ? `.${decimalPart}` : ''}`)

        // Return the formatted string.
        return `${integerPartWithCommas}${decimalPart ? `.${decimalPart}` : ''}`;

        
    }
    return <>
        {
            formattedMoney ? <span>{formattedMoney}</span>: "not form"
        }
    </>
}

export default FormatMoneyComponent;