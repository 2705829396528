
import { toast } from "react-toastify";
import Constants from "../../constants/Constants";
import axios from "../axios";
// import { checkToken } from "../utils/Utils";
import authHeader from "./auth-header";
import RequestService from "./RequestService";
let SavingsService = {
    fetchOneSaving: async (id) => {
        let url = Constants.SAVINGS.ONE;
        try {
            let req = await axios.get(`${url}/${id}`, {
                headers: {
                    Authorization: `Bearer ${authHeader()}`
                }
            });
            if (req.data) {
                let { type, data } = req.data;
                console.log(type)
            }
            return req.data;
        }
        catch (e) {
            console.log(e);
            toast.error("There seems to be a network connection issue");
        }
    },
    fetchAllSavings: async () => {

    },
    create : async (data) => {
        let url = Constants.SAVINGS.NEW;
        let req = await RequestService(url, true, data, true);
        console.log(req);
        return req;
    }
}

export default SavingsService