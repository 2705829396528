import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopComponent from "../components/landingpage/TopComponent";
import ModalRootComponent from "../components/modals/ModalRootComponent";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.svg";
import ButtonComponent from "../components/ButtonComponent";
import LogoComponent from "../components/LogoComponent";

let VerificationError = ( ) => {
    return <>
        <div>
            <ModalRootComponent
                children={
                    <div className="flex flex-col mulish-font bg-white items-center p-10 justify-center gap-6">
                        <div className="flex justify-center">
                            <LogoComponent></LogoComponent>
                        </div>
                        <div className=" drop-shadow-2xl p-6 rounded-full ">

                            <FontAwesomeIcon className="text-6xl text-red-600 animate-bounce" icon={faThumbsDown}></FontAwesomeIcon>
                        </div>


                        <p className="text-2xl text-red-600">Error verifying onboarding link </p>
                        <Link to={"/onboard"}>
                            <ButtonComponent text="Retry onboarding"></ButtonComponent>
                        </Link>
                    </div>
                }
            ></ModalRootComponent>
        </div>
    </>
}

export default VerificationError;