import { faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

let SavingPlan = ({ id, name, amount, status, example }) => {
    let handleStatus = () => {
        switch (status) {
            case "ongoing":
                return <div className="px-[9px] py-[4px]">

                </div>
            case "matured":
                return <div className="px-[9px] py-[4px]">

                </div>
            default:
                break;
        }
    }
    return <>
        <Link to={`/dashboard/save/${id}`}>
            <div className={`w-full px-6 py-[18px] mulish-font rounded-xl bg-rouzo-blue-light flex ${example ? "animate-pulse" : ""}`}>
                <div className="flex space-x-4">
                    <FontAwesomeIcon className="items-center self-center text-4xl" flip="horizontal" icon={faPiggyBank} style={{ color: "#2709BB" }} />
                    <div className="flex flex-col">
                        <p className="text-rouzo-text-color-2 font-bold text-base">{name}</p>
                        <p className="font-bold text-[14px] text-rouzo-text-color"> ₦ {amount}</p>
                    </div>
                </div>
                <div>
                    {handleStatus()}
                </div>
            </div>
        </Link>
    </>
}

export default SavingPlan;