
import Constants from "../../constants/Constants";
import axios from "../axios";
// import { checkToken } from "../utils/Utils";
import authHeader from "./auth-header";

let UserService = {
    fetchProfile : async (auth) => {
        let url = Constants.USER.PROFILE;
        let req = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${auth}`
            }
        });
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
       
        return req.data;
    }
}

export default UserService