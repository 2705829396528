
//import { toast } from "react-toastify";
import axios from "../axios";

import authHeader from "./auth-header";
//import { useNavigate } from "react-router-dom";


let RequestService = async (url, post, data, auth) => {
   // let navigate = useNavigate();
    let opt = {
        method: post ? "post" : "get",
        headers: {
            "Authorization": auth ? `Bearer ${authHeader()}` : ""
        }
    }
    if(post) opt.body = JSON.stringify(data);

    try {
        console.log(opt.method);
        let _req = await axios[opt.method](url, data,opt);
        console.log(_req.data);
        return _req.data;
    }
    catch (e) {
        console.log(e);

        {/*if (e.response && e.response.status == 401) {
            navigate("/dashboard/notify/error/Session expired. Please do login");
            setTimeout(() => {
                navigate("/login");
            }, 2000)
        }*/}
        return e;

    }


}

export default RequestService