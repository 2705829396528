import { useState, React, createContext } from "react";


export const OnboardContext = createContext();

// Create an exportable consumer that can be injected into components
export const OnboardConsumer = OnboardContext.Consumer
// Create the provider using a traditional React.Component class
const OnboardProvider = ({children}) => {
    let [firstName, setFirstName] = useState("");
    let [lastName, setLastName] = useState("");
    let [mobileNumber, setMobileNumber] = useState("");
    let [password, setPassword] = useState("");
    let [emailAddress, setEmailAddress] = useState("");
    let [address , setAddress] = useState("");
    let [usingEmail, setUsingEmail] = useState(false);
    let [otpFor, setOtpFor] = useState("login");
    let [investorType, setInvestorType] = useState();
    return (
        // value prop is where we define what values 
        // that are accessible to consumer components
        <OnboardContext.Provider value={{
                firstName, setFirstName,
                lastName, setLastName,
                address, setAddress,
                mobileNumber, setMobileNumber,
                emailAddress, setEmailAddress,
                usingEmail, setUsingEmail,
                otpFor, setOtpFor,
                password , setPassword,
                investorType, setInvestorType
            }}>
            {children}
        </OnboardContext.Provider>
    )
}
export default OnboardProvider