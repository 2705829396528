

let SelectComponent2 = ({data, disabled,selected, onChange}) => {
    console.log(data);
    return <>
        <div className="mulish-font h-full">
            <select onChange={onChange} disabled={disabled ? true : false} className="h-full p-2 text-sm md:px-[32px] w-full md:pt-[15px] md:pb-[12px]  bg-rouzo-blue-light rounded-xl">
                {data ? data.map(d => {
                    return <option className="mulish-font text-base " value={d.value}>{d.text}</option>
                }) : ""}
            </select>
        </div>
    </>
}

export default SelectComponent2;