import { Link } from "react-router-dom";
import LogoComponent from "../LogoComponent";

import Logo from '../../assets/img/logo.svg';

let FooterComponent = () => {
    return <>
        <div className="flex flex-col px-[100px] mulish-font gap-10 bg-rouzo-blue-gray pt-[80px]">
            <div className="flex flex-col md:flex-row justify-between">
                <div className="flex-col justify-start">
                    <div className="">
                        <img className='mb-0 h-24' src={Logo}></img>
                    </div>

                    <p>Smart finance for a green future.</p>
                    <div className="flex-col flex font-bold text-black ">
                        <p className="py-4">25b Adewale Kolawole Crescent, off T.F Kuboye, <p>Maruwa, Lekki Phase 1, Lagos.</p></p>
                        <p className="font-bold">
                            Hello@rouzo.co
                            <p>+234 912 541 8144</p>
                            <p>+234 704 415 2333</p>
                        </p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row gap-24 ">
                    <div className="text-base">

                        <p className="font-bold">About</p>
                        <Link>
                            <p>About us</p>
                        </Link>
                        <Link>
                            <p>Features</p>
                        </Link>
                    </div>
                    <div>
                        <p className="font-bold ">Support</p>
                        <div className="flex-col gap-4">
                            <Link>
                                <p>FAQs</p>
                            </Link>
                            <Link>
                                <p>Contact us</p>
                            </Link>
                        </div>

                    </div>
                    <div>
                        <p className="font-bold">Follow us</p>
                        <div className="flex-col gap-4">
                            <div className="flex">

                                <Link to="">
                                    <p className="">Facebook</p>
                                </Link>
                            </div>
                            <div className="flex">

                                <Link to="https://www.instagram.com/rouzoco/">
                                    <p className="">Instagram</p>
                                </Link>
                            </div>
                            <div className="flex">

                                <Link to="https://twitter.com/rouzoco">
                                    <p className="">Twitter</p>
                                </Link>
                            </div>
                            <div className="flex">

                                <Link to="https://www.linkedin.com/company/rouzo/">
                                    <p className="">Linkedin</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <hr className="m-0">
                </hr>
            </div>
            <div className="flex flex-col md:flex-row justify-between p-4 pt-0 pb-8">
                <div>
                    <p>{new Date().getFullYear()} Rouzo - powered by Owoafara. All rights reserved</p>
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                    <Link>Privacy Policy</Link>
                    <Link>Terms of Service</Link>
                    <Link>Cookie Settings</Link>
                </div>
            </div>
        </div>
    </>
}


export default FooterComponent;