import PortfolioComponent from "./PortfolioComponent";



let PortfolioListComponent = (props) => {
    let { data } = props;
    data = data.filter(o => o.show)
    console.log(data)
    return <>
        <div className="md:flex gap-6 overflow-x-auto">
            {data ? data.map((port, index) => {
                return <PortfolioComponent index={index} {...port} ></PortfolioComponent>
            }) :
                <PortfolioComponent interestRate="20" name="Asset Finance" riskLevel="high" ></PortfolioComponent>
            }
        </div>
    </>

}


export default PortfolioListComponent;