import { useEffect, useState } from "react";


let SavingPlanProgressComponent = ({ current, expected, interval }) => {
    let [progressAmount, setProgressAmount] = useState(null);

    useEffect(()=> {
        if(!progressAmount){
            let _progressAmount = ((current)  / expected ) * 100;
            console.log(expected)
            console.log(current)
            console.log("Progress: ",((current)  / expected ) * 100);
            console.log(_progressAmount);
            setProgressAmount(_progressAmount);
        }
    })
    return <>
        <div className="border border-rouzo-base-color-op bg-rouzo-base-color-op mulish-font p-2 rounded-xl">
            <div className="flex items-center justify-between">
                <p className="font-medium text-base text-rouzo-text-color">Saving Plan Progress</p>
                {progressAmount == 100 ? <p className="px-[4px] rounded-full text-xs border border-rouzo-green-light  text-rouzo-green-light">completed</p> : <p className="px-[4px]  rounded-full text-xs border border-rouzo-base-color-op bg-rouzo-grey ">in progress</p>}
            </div>
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                <div className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{width :  progressAmount ? `${progressAmount}%` : 0}}> {progressAmount ? progressAmount : 0}%</div>
            </div>
            <div className="flex justify-end">
                <p className="font-semibold text-sm mulish-font ">ends in <span className="text-rouzo-base-color">{expected - current} months</span></p>
            </div>
        </div>
    </>
}


export default SavingPlanProgressComponent;