import { useContext } from "react";
import { OnboardContext } from "../context/OnboardContext";
import man from "../assets/img/short-hair-man-with-beard.png";
import woman from "../assets/img/long-hair-woman.png";
import { motion } from "framer-motion";
let InvestorCategoryList = ({ investorTypes }) => {
    let { investorType, setInvestorType } = useContext(OnboardContext);

    let handleInvType = (e,id) => {

        
        e.target.classList.add("bg-rouzo-base-color","text-white");
        console.log(e.target.classList);

        setInvestorType(id);
    }
    let myClass = () => {
        //console.log(this)
    }

    return <>
        <div className="flex p-6 gap-6 overflow-x-auto md:w-full">
            {investorTypes ? investorTypes.map(investorType => {
                return <div onClick={(e) => handleInvType(e,investorType.id)} className="flex shrink flex-col p-[23px] gap-[20px] rounded-lg justify-center text-center border border-rouzo-grey hover:cursor-pointer">
                    {investorType.icon != 3 ?
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            {myClass(this)}
                            <img className=" shrink rounded-lg" src={investorType.icon == 1 ? man : woman}></img>
                        </motion.div>
                        :
                        <motion.div
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                            className="flex shrink"
                        >
                            <img className=" shrink rounded-lg" src={man}></img>
                            <img className="shrink rounded-lg" src={woman}></img>
                        </motion.div>

                    }
                    <p className="font-bold font-base text-rouzo-text-color-2">{investorType.name}</p>
                </div>
            }) : ""}
        </div>

    </>
}

export default InvestorCategoryList;