
import { RouterProvider } from 'react-router-dom/dist';
import './App.css';
import homeRouter from './routes/homeRoutes';

function App() {
  return <>
      <RouterProvider router={homeRouter}>
      </RouterProvider>

  </>

}

export default App;
