
import cardBackground from "../assets/img/wave.svg";
import Constants from "../constants/Constants";

let BalanceCardComponent = ({ balanceText, amount, earningsValue }) => {
    return <>
        <div style={{ background: `url('${cardBackground}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} className='mulish-font p-6 pt-[32px] pb-[32px] bg-rouzo-blue-light rounded-[20px]'>
            <p className='mb-6 font-medium text-base text-rouzo-text-color'>{balanceText}</p>
            <p className='mb-6 font-bold text-[32px] text-rouzo-text-color-2'>₦{amount} </p>
            <div className="flex gap-x-3 items-center">
                <p className="text-medium text-sm text-rouzo-text-color"></p>
                {
                    earningsValue ? <p className="font-semibold text-base text-rouzo-text-color-2" >Earnings : {Constants.nairaString} {earningsValue}</p> 
                    : 
                    ""
                }
                
            </div>

        </div>

    </>
}

export default BalanceCardComponent;