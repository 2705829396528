

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Routes
} from "react-router-dom";

import DashboardLayout from "../pages/dashboard/DashboardLayout";
import Onboard from "../pages/auth/Onboard";
import Login from "../pages/auth/Login";
import Layout from "../layouts/Layout";
import PrivateRoute from "./privateRoute";
import SavingDashboard from "../pages/dashboard/SavingDashboard";
import OnboardLayout from '../layouts/OnboardLayout';
import Error404 from '../pages/error404';
import SavingLayout from "../pages/dashboard/SavingLayout";
import AccountLayout from "../layouts/AccountLayout";
import InvestmentLayout from "../pages/dashboard/InvestmentLayout";
import SolarFi from "../pages/solarfi";
import VerificationSuccess from "../pages/VerificationSuccess";
import VerificationError from "../pages/VerificationError";
import SavingsSummary from "../pages/investment/SavingsSummary";
import AssetFinanceSummary from "../pages/investment/AssetFinanceSummary";
import GreenAssetFinanceSummary from "../pages/investment/GreenAssetFinanceSummary";
import SavingPlanSummaryLayout from "../pages/savingplan/SavingPlanSummaryLayout";
import InvestmentSummaryLayout from "../pages/investment/InvestmentSummaryLayout";
import SettingsLayout from "../pages/settings/SettingsLayout";
import Notification from "../pages/Notification";
import Logout from "../pages/Logout";
import LandingPage from "../pages/LandingPage";
import InclusionPage from "../pages/Inclusion";


const homeRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Layout />}>
        {/*<Route path="/solarFi" element={<SolarFi />}></Route>
        <Route path="/SolarFi" element={<SolarFi />}></Route>
        <Route path="/solarfi" element={<SolarFi />}></Route>
        */}
        <Route path="/loans" element={<SolarFi />}></Route>
        <Route path="/inclusion" element={<InclusionPage />}></Route>
        <Route path="/verify/success" element={<VerificationSuccess />}></Route>
        <Route path="/verify/error" element={<VerificationError />}></Route>

        <Route element={<OnboardLayout />}>
          <Route path="/" element={<LandingPage />}></Route>
          <Route path="login" element={<Login />}></Route>
          <Route path="onboard" element={<Onboard />}></Route>



        </Route>
      </Route>
      <Route element={<Layout />}>
        <Route element={<PrivateRoute />}>
          <Route element={< AccountLayout />}>
            <Route path="/dashboard/logout" element={<Logout logout={true} />}></Route>
            <Route path="/dashboard/home" element={<DashboardLayout />}> </Route>
            <Route path="/dashboard/save" element={<SavingLayout />}> </Route>
            <Route path="/dashboard/settings" element={<SettingsLayout />}> </Route>
            <Route path="/dashboard/notify/error/:message" element={<Notification error={true} />}> </Route>
            <Route path="/dashboard/notify/success/:message" element={<Notification />}> </Route>
            <Route path="/dashboard/save/:savingPlanId" element={<SavingPlanSummaryLayout />}> </Route>
            <Route path="/dashboard/invest" element={<InvestmentLayout />}> </Route>
            <Route path="/dashboard/invest/:investmentId" element={<InvestmentSummaryLayout />}> </Route>
            <Route path="/dashboard/portfolio/savings/summary" element={<SavingsSummary />}> </Route>
            <Route path="/dashboard/portfolio/asset-finance/summary" element={<AssetFinanceSummary />}> </Route>
            <Route path="/dashboard/portfolio/green-asset-finance/summary" element={<GreenAssetFinanceSummary />}> </Route>
            
            {/*<Route path="/dashboard" element={<DashboardLayout />}> </Route>*/}
          </Route>
        </Route>
      </Route>

    </Route>

  )
);


export default homeRouter;
