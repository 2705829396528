import React, { useState } from "react";
import LogoComponent from "./LogoComponent";
import NavbarButtonComponent from "./NavbarComponent";

import { faHouse, faPiggyBank, faChartLine, faRightFromBracket, faCog } from "@fortawesome/free-solid-svg-icons";

import { Link, useNavigate } from "react-router-dom";
import DashboardLayout from "../pages/dashboard/DashboardLayout";

let SideBarComponent = ({ theActive }) => {
    let [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();

    return <>
        <div className="md:block hidden bg-rouzo-blue-light p-6 pt-[27px] h-100 relative">
            <LogoComponent></LogoComponent>
            <div className="pt-[26px] pb-6">
                <p className="mulish-font text-base text-rouzo-text-color mb-6">Menu</p>
                <div className="flex-col flex gap-y-2 ">
                    <Link to={"/dashboard/home"}>
                        <NavbarButtonComponent text="Home" goTo="" icon={faHouse} active={(theActive == "home") ? true : false} />
                    </Link>
                    <Link to={"/dashboard/save"}>
                        <NavbarButtonComponent text="Saving" goTo="" flip="horizontal" icon={faPiggyBank} active={(theActive == "save") ? true : false} />
                    </Link>
                    <Link to={"/dashboard/invest"}>
                        <NavbarButtonComponent text="Investment" goTo="" icon={faChartLine} active={(theActive == "invest") ? true : false} />
                    </Link>
                    
                </div>
            </div>
            <div className=" relative bottom-0">
                <hr className="my-4"></hr>
                <div className="mulish-font text-base text-rouzo-text-color mb-6" >
                    <p className="">Preference</p>
                </div>
                <Link to="/dashboard/settings">
                    <NavbarButtonComponent text="Settings" goTo="" icon={faCog} flip="horizontal" active={(theActive == "settings") ? true : false} />
                </Link>
                <Link to="/dashboard/logout">
                    <NavbarButtonComponent text="Logout" goTo="" icon={faRightFromBracket} flip="horizontal" active={(theActive == "") ? true : false} />
                </Link>
            </div>
        </div>
    </>
}

export default SideBarComponent;