import { useState } from "react";
import SideBarComponent from "../../components/SideBarComponent";
import PortfolioSummaryComponent from "../../components/investments/PortfolioSummaryComponent";
import NewInvestment from "../../components/modals/NewInvestment";
import Constants from "../../constants/Constants";
import MobileNavBarComponent from "../../components/navbar/MobileNavBarComponent";
import NavBackComponent from "../../components/misc/NavBackComponent";


let SavingsSummary = () => {
    let [isNewInvestmentModalActive, setIsNewInvestmentModalActive] = useState(false);
    let handleInvestModal = () => {
        setIsNewInvestmentModalActive(true);
        console.log("xlixxxx");
    }
    return <>
        <div className="flex w-full h-full mb-20">
            <SideBarComponent theActive="invest"></SideBarComponent>
            <div className="px-10 pt-10 flex-grow">
                <div className="mb-4">
                    <NavBackComponent title={"Savings Vault Portfolio"}></NavBackComponent>
                </div>
                <PortfolioSummaryComponent riskLevel={"low"}
                    portfolioSize={"100m"}
                    insurancePartner={"Allianz"}
                    pricePerUnit={1000}
                    name={"Savings Portfolio"}
                    portfolioDescription={"This portfolio allows you to save and lock your money in the vault anytime you want. You can choose the duration of each saving when you want to save. You can get up to 15% per annum on your savings. The minimum amount is 1,000"}
                    interestRate={"15%"}
                    onClick={handleInvestModal}
                    numberOfUnits={100000}></PortfolioSummaryComponent>
            </div>
            {isNewInvestmentModalActive ? <NewInvestment portfolio={Constants.PORTFOLIOS[0]} setIsNewInvestmentModalActive={setIsNewInvestmentModalActive}></NewInvestment> : ""}
        </div>
        <MobileNavBarComponent></MobileNavBarComponent>
    </>
}

export default SavingsSummary;