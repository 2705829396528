import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";


let Notification =({error}) => {
    let navigate =  useNavigate()
    let {message} = useParams();
    useEffect(() => {
        if(error){
            toast.error(message ? message : "An error occured");
            
        }
        else {
            toast.success(message ? message : "Success")
        }
        return navigate("/dashboard/home")
    })
    return <>
        <div>

        </div>
    </>
}

export default Notification;