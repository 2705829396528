import SavingPlan from "./SavingPlan";


let SavingPlansList = ({ savingplans }) => {
    return <>
        {savingplans ? savingplans.reverse().map(plan => {
            return <div className="mb-3">
                <SavingPlan {...plan}></SavingPlan>
            </div>
        }) :
            <SavingPlan example={true} name={"Travel to Manitoba"} amount={20000} status={"ongoing"} ></SavingPlan>
        }
    </>
}


export default SavingPlansList;
