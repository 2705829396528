import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";



let MobileNavItem = ({ icon, text, to, theActive, customColor, className }) => {
    return <>
        <Link to={to}>
            <div className="flex flex-col mulish-font items-center">
                <FontAwesomeIcon className={`${customColor ? "text-" + customColor : ""}`} icon={icon}></FontAwesomeIcon>
                <p className={`${customColor ? "text-" + customColor : ""} font-bold text-sm  ${className ? className : ""}`}>{text}</p>
            </div>
        </Link>
    </>
}

export default MobileNavItem;