
import HomeDashboard from '../../pages/dashboard/Dashboard';
import SideBarComponent from '../../components/SideBarComponent';
import { motion } from "framer-motion";
import MobileNavBarComponent from '../../components/navbar/MobileNavBarComponent';


let DashboardLayout = () => {
    return <>

        {/*initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
transition={{ duration: 0.5 }}*/}

        <div className="flex w-full h-full mb-20 ">
            <SideBarComponent theActive="home"></SideBarComponent>

            <HomeDashboard></HomeDashboard>
            
        </div>
        <MobileNavBarComponent></MobileNavBarComponent>

    </>
}


export default DashboardLayout;