

import SideBarComponent from '../../components/SideBarComponent';
import SavingDashboard from './SavingDashboard';
import MobileNavBarComponent from '../../components/navbar/MobileNavBarComponent';
import useAccounts from '../../hooks/useAccount';
import { useContext, useEffect } from 'react';
import { AccountContext } from '../../context/AccountContext';

let SavingLayout = () => {
    let {investor} = useContext(AccountContext);
    let {fetchProfile} = useAccounts();
    useEffect(() => {
        //!accounts ? fetchAllAccounts() : "";
        console.log(investor);
        if (Object.keys(investor).length == 0) {
            console.log("Fetching profile");
            console.log(investor);
            fetchProfile()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [investor]);
    return <>
        <div className="flex w-full h-full mb-20">
            <SideBarComponent theActive="save"></SideBarComponent>   
            <SavingDashboard></SavingDashboard>
        </div>
        <MobileNavBarComponent></MobileNavBarComponent>
    </>
}


export default SavingLayout;