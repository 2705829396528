import { useEffect, useState } from "react";
import ImageBg from "../../assets/img/pexels-matthew-montrone-1179229.jpg"
import Constants from "../../constants/Constants";
import FormatMoneyComponent from "../misc/FormatMoneyComponent";


let InterestOnInvestmentComponent = ({ startDate, roi, timeSpanInMonths }) => {
    let [interestEarned, setInterestEarned] = useState(null);

    useEffect(() => {
        if (!interestEarned) {
            calculateInterest();
        }
    }, [])

    const monthDiff = (d1, d2) => {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    let calculateMonthDiff = (d1, d2) => {
        let months = 0;
        console.log(d1);
        console.log(d2.getFullYear());
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        /*console.log(_months);
        console.log(new Date(d1).getMonth());
        months = (_months - d1.getMonth()) - d2.getMonth()
        console.log(months);*/
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    let calculateDaysDiff = (d1, d2) => {
        let diffInMs = new Date(d1) - new Date(d2)
        let diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return diffInDays <= 0 ? 0 : diffInDays;
    }
    let calculateInterest = () => {
        // Convert the start date to a JavaScript Date object.
        console.log(timeSpanInMonths);
        let endDate = new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() + Number(timeSpanInMonths)));
        console.log("End date: ", endDate);
        let currentDate = new Date();
        let passedEndDate = ((new Date(currentDate).getTime() - new Date(endDate).getTime()) > 0) ? true : false;
        console.log("Passed End Date: ", passedEndDate);
        if (passedEndDate) {
            currentDate = endDate;
            //return setInterestEarned(roi);
        }
        console.log("Current Date: ", currentDate);

        let monthsPassed = calculateMonthDiff(new Date(currentDate), new Date(startDate));
        let daysPassed = calculateDaysDiff(new Date(currentDate), new Date(startDate));
        let daysBetweenStartAndEndOfInvestment = calculateDaysDiff(new Date(endDate), new Date(startDate));
        console.log("Months Passed  : ", monthsPassed)
        console.log("days Passed  : ", daysPassed);
        let _interestEarned = daysPassed * (roi / daysBetweenStartAndEndOfInvestment);
        _interestEarned = Number(_interestEarned).toFixed(2);
        console.log("Precise Interest: ", _interestEarned);
        setInterestEarned(_interestEarned);
        // Return the interest earned.
        return _interestEarned;
    }
    return <>
        <div className="border items-center justify-between rounded-xl mulish-font flex border-1 p-4 bg-rouzo-green-light">
            <p className="p-1 rounded-full font-semibold text-base text-white ptext-rouzo-green-light">Interest earned till date</p>
            <p className="p-4 font-extrabold text-2xl bg-white rounded-xl text-rouzo-green-light ">{Constants.nairaString} {interestEarned ? <FormatMoneyComponent amount={interestEarned}></FormatMoneyComponent> : "0"}</p>
        </div>
    </>
}


export default InterestOnInvestmentComponent;