
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AccountContext } from "../../context/AccountContext";
import NavBackComponent from "../../components/misc/NavBackComponent";
import { toast } from "react-toastify";
import BalanceCardComponent from "../../components/BalanceCardComponent";
//import InvestmentProgressComponent from "../../components/savings/InvestmentProgressComponent";

import useAccounts from "../../hooks/useAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import InterestOnInvestmentComponent from "../../components/investments/InterestOnInvestmentComponent";
import FormatMoneyComponent from "../../components/misc/FormatMoneyComponent";


let InvestmentSummary = () => {
    let navigate = useNavigate();

    let { investmentId } = useParams();
    let { investmentHistory } = useContext(AccountContext);
    let [investment, setInvestment] = useState(null);
    let { fetchProfile } = useAccounts();

    useEffect(() => {

        if (investmentHistory) {
            let _investment = investmentHistory.filter(o => o.id == investmentId)[0];
            console.log(_investment);
            if (_investment == null) {
                toast.error("Investment does not exist");
                navigate("/dashboard/invest");
            }
            else {
                setInvestment(_investment);
            }
        }
        else {
            fetchProfile();
        }
    }, [])
    return <>
        <div className="pt-9 pl-10 pr-10 mulish-font flex-grow">
            <div className="mb-4">
                <NavBackComponent title={`#${investment ? investment.id : ""} Investment Summary`}></NavBackComponent>
            </div>
            <div className="md:grid md:grid-col-2  mb-10 gap-4">
                <BalanceCardComponent 
                    earningsValue={investment ? investment.roi : 0} 
                    balanceText={"Investment amount"} 
                    amount={investment ? <FormatMoneyComponent amount={investment.amount}></FormatMoneyComponent> : "0"}>

                </BalanceCardComponent>
                <div className="md:mt-0 mt-4 flex-col items-center space-x-6 justify-center">
                    {/*<InvestmentProgressComponent current={investment ? investment.monthsPaid : 0} expected={investment ? investment.months : 0}
                        interval={investment ? `${investment.interval == "daily" ? "days" : investment.interval == "weekly" ? "weeks" : "months"}` : "daily"}></InvestmentProgressComponent>
                    */}
                    <div className="flex justify-between border-2 border-rouzo-base-color-op rounded-xl p-4">
                        <div className="flex-col items-center justify-center text-center">
                            <p className="mulish-font text-xl text-rouzo-base-color">{investment ? investment.unitsBought : 0}</p>
                            <p className="mulish-font text-rouzo-text-color text-sm">units</p>
                        </div>
                        <div className="text-right">
                            <div className="flex mulish-font text-right justify-end items-center gap-2">
                                <FontAwesomeIcon icon={faLock}> </FontAwesomeIcon>
                                <p className="font-bold text-base text-rouzo-text-color-2">{investment ? investment.period : 0} months</p>
                            </div>
                            <div className="text-right">
                                <p className="text-sm text-rouzo-text-color">Purchased at <span>{investment ? investment.datePurchased.toLocaleString() : new Date().toLocaleDateString()}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    {
                        investment ? <InterestOnInvestmentComponent startDate={investment.datePurchased} amountInvested={investment.amount} roi={investment.roi} timeSpanInMonths={investment.period}></InterestOnInvestmentComponent>
                            : ""
                    }

                </div>
            </div>
        </div>
    </>
}


export default InvestmentSummary;