import TransactionService from "../../api/services/TransactionService";
import { AccountContext } from "../../context/AccountContext";
import ModalRootComponent from "./ModalRootComponent";
import { toast } from "react-toastify";
import { useState, useContext } from "react";
import InputComponent from "../InputComponent";
import CTAButtonComponent from "../CTAButtonComponent";

let FundStashComponent = ({setIsModalActive}) => {
    let [amount, setAmount] = useState(0);
    let [errorMsg , setErrorMsg] = useState("");
    let {investor, fetchProfile} = useContext(AccountContext);
    let [isLoading, setIsLoading] = useState(false);
    let handleFunding = async () => {
        if(isLoading == true){
            return true;
        }
        setIsLoading(true);
        if(!amount ){
            return toast.error("Come on!, you can do more than that");
        }
        if(amount < 100){
            console.log("Come on!, you can do more than that");
            return toast.error("Come on!, you can do more than that");
        }
        let funding = await TransactionService.fund({
            amount : amount,
            email : investor.email,
            type : 'crd',
            businessId : investor.id
        })
        console.log(funding);
        if(funding){
            if(funding.type == "success"){
                toast.success("Redirecting to payment gateway shortly");
                let {redirect_url} = funding.data;
                setTimeout(()=>{
                    window.location = redirect_url;
                }, 3000);
            }
            else {
                toast.error("Error creating funding request");
            }
            setIsLoading(false);
        }
    }
    let handleAmount = (e) => {
        setAmount(e.target.value);

        if(amount < 100){
            setErrorMsg("Come on!, you can do more than that");
        }
        else{
            setErrorMsg("");
        }
    }
    return <>
        <ModalRootComponent
            children={
                <div className="z20 mulish-font rounded-2xl self-center p-6 pt-9 bg-white">
                    <div onClick={() => setIsModalActive(false)} className="hover:cursor-pointer text-rouzo-text-color-2 font-medium text-xl">
                        <p>{'x'} close</p>
                    </div>
                    <div className="mb-6">
                        <p className="text-rouzo-text-color-2 font-bold text-[32px] mb-6">Fund your Stash</p>
                        <div>
                            <p className="text-rouzo-text-color-2 font-medium text-[24px] mb-6">Amount (₦) </p>
                            <InputComponent onChange={(e) => handleAmount(e)} type="number" placeholder="₦ 100000"></InputComponent>
                        </div>
                    </div>
                    <div className="text-right flex justify-end ">
                        <div >
                            <CTAButtonComponent isLoading={isLoading} onClick={() => handleFunding()} text={"Proceed"}></CTAButtonComponent>
                            
                        </div>
                    </div>
                </div>
            }
        >

        </ModalRootComponent>
    </>
}


export default FundStashComponent;