import axios from "axios";

export default axios.create({
   baseURL: "https://staging.api.rouzo.co/api",//process.env.BASE_URL\
   //baseURL : "http://127.0.0.1:8764/api",
   headers: {
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      "mode" : "no-cors"
   },
   withCredentials : false,
   
})