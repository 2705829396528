import { useContext } from "react";
import ButtonComponent from "../ButtonComponent";
import CTAButtonComponent from "../CTAButtonComponent";
import { AccountContext } from "../../context/AccountContext";
import { Link } from "react-router-dom";


let PortfolioComponent = (props) => {
    let {setSelectedPortfolio} = useContext(AccountContext);
    let { name, interestRate, riskLevel, pricePerUnit, img , id, index, to} = props;
    let handleSelect = (index) => {
        console.log("___Select");
        console.log(index);
        setSelectedPortfolio(index)
        
    }
    return <>
        <div className="border rounded-2xl md:max-w-sm mulish-font md:min-w-[300px]">
            <img className="max-h-48 w-full rounded-2xl" src={img}>

            </img>
            <div className="py-6 px-4 flex flex-col space-y-4 mulish-font">

                <p className="font-bold text-lg text-rouzo-text-color-2 mulish-font">{name}</p>
                <p className="font-medium text-base mulish-font text-rouzo-text-color">Annual Interest: {interestRate}%</p>
                <div className="flex-col">
                    <p className="font-medium text-base text-rouzo-text-color mulish-font">Risk Level: {riskLevel}</p>
                </div>
                <p className="font-bold text-rouzo-base-color mulish-font">₦{pricePerUnit} per unit</p>
                <hr></hr>
                <div className=" flex justify-end text-right">
                   <Link to={to}> <CTAButtonComponent text={"View Details"}></CTAButtonComponent></Link>
                </div>
            </div>


        </div>
    </>
}


export default PortfolioComponent;