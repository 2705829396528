import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { useAuthContext } from "../hooks/useAuth";

let Logout = ({ logout }) => {
    let navigate = useNavigate()
    let { message } = useParams();
    const { dispatch } = useAuthContext();
    useEffect(() => {
        if (logout) {
            toast.success("Logging you out..");
            setTimeout(() => {
                handleLogout()
            }, 2000);
        }
        else {
            return navigate("/dashboard/home");
        }
    })

    let handleLogout = () => {
        localStorage.setItem("rouzo2.0_token", "");
        dispatch({
            type: "LOGOUT",
        })
    }
    return <>
        <div>

        </div>
    </>
}

export default Logout;