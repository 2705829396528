import { useNavigate } from "react-router-dom";


let NavBackComponent = ({title}) => {
    const navigate = useNavigate();
    let back = async () => {
        console.log("Back button clicked");
        navigate(-1);
    }
    return <>
        <div onClick={() => back()} className="hover:cursor-pointer mulish-font md:w-full flex text-rouzo-text-color-2 items-center">
            <p className="text-sm font-bold">{'<'} back</p> 
            <div className="flex justify-center flex-grow">
                <p className="mulish-font font-medium text-xl text-rouzo-text-color-2 ">{title}</p>
            </div>
            
        </div>
    </>
}


export default NavBackComponent